import '../styles/sideBar.css'
import React, { useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { logout } from '../actions/userActions'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ProtectedRoute from '../components/ProtectedRoute'

function SideBar({ onNavigate, editFlag }) {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const userLogin = useSelector(state => state.userLogin)
    const {loading, error, userInfo, logout: logoutState} = userLogin

    useEffect(() => {
        if(logoutState) {
            navigate('/')
        }
    }, [userLogin])


    const retractHandler = () => {
        const sideText = document.querySelectorAll('.side-text')
        sideText.forEach(item => {
            item.style.display = 'none'
        })
        let sideUser = document.getElementById('side-user')
        sideUser.style.justifyContent = 'center'

        let sideActions = document.getElementsByClassName('side-actions')[0]
        sideActions.style.justifyContent = 'center'

        let rightArrow = document.getElementById('right-arrow')
        rightArrow.style.display = 'block'

        let leftArrow = document.getElementById('left-arrow')
        leftArrow.style.display = 'none'

        let sideBar = document.getElementById('side-bar')
        sideBar.style.padding = '0'
        sideBar.style.maxWidth = '100px'
    }

    const openHandler = () => {
        const sideText = document.querySelectorAll('.side-text')
        sideText.forEach(item => {
            item.style.display = 'block'
            // item.style.display = 'none'
        })
        let sideUser = document.getElementById('side-user')
        sideUser.style.justifyContent = 'flex-start'

        let sideActions = document.getElementsByClassName('side-actions')[0]
        sideActions.style.justifyContent = 'end'

        let rightArrow = document.getElementById('right-arrow')
        rightArrow.style.display = 'none'

        let leftArrow = document.getElementById('left-arrow')
        leftArrow.style.display = 'block'


        let sideBar = document.getElementById('side-bar')
        sideBar.style.padding = '0 1em'
        sideBar.style.maxWidth = '300px'
    }

    const logoutHandler = () => {
        dispatch(logout(userInfo.refresh))
    }

    const handleLinkClick = (e, path) => {
        if (editFlag) {
          const confirmNavigation = window.confirm('You have unsaved changes. ¿Are you sure you want to leave?');
          if (!confirmNavigation) {
            e.preventDefault(); // Bloquea la navegación
          } else {
            onNavigate(path); // Llama a la navegación si el usuario confirma
          }
        } else {
          navigate(path); // Navega directamente si no hay cambios sin guardar
        }
      };

  const responsiveCloseHandler = () => {
    var sideObject = document.getElementById('side-bar')
    var toggleButton = document.getElementById('toggle-btn')
    // sideObject.style.display = 'none'
    if (sideObject.classList.contains('hidden')) {
      // Si está oculto, lo mostramos
      sideObject.classList.remove('hidden');
    } else {
      // Si está visible, lo ocultamos
      sideObject.classList.add('hidden');
    }
  }


  return (
    <div id='side-bar' className='side-container'>
      <div className='side-actions'>
        <i id='left-arrow' onClick={retractHandler} className="fa-solid fa-angles-left"></i>
        <i id='right-arrow' onClick={openHandler} className="fa-solid fa-angles-right"></i>
      </div>
      <div onClick={responsiveCloseHandler} id='toggle-btn' className='side-responsive-close'>
        <i className="fa-regular fa-circle-xmark"></i>
      </div>

      <div id='side-user' className='side-user'>
        <img src={`/static/account/${(userInfo && userInfo.profile_image) ? userInfo.profile_image: 'unknown.svg'}`} alt="" />
        <LinkContainer to={'/account'}>
          <p className='side-text'>{userInfo && userInfo.name}</p>
        </LinkContainer>
      </div>

      <div className='side-item' onClick={(e) => handleLinkClick(e, '/home')}>
        <i className="fa-regular fa-flag"></i>
        <p className='side-text'>Home</p>
      </div>

      <div className='side-item' onClick={(e) => handleLinkClick(e, '/dashboard')}>
        <i className="fa-solid fa-chart-column"></i>
        <p className='side-text'>Dashboard</p>
      </div>

      <div className='side-item' onClick={(e) => handleLinkClick(e, '/favorite')}>
        <i className="fa-regular fa-star"></i>
        <p className='side-text'>Favorite</p>
      </div>

      <div className='side-item' onClick={(e) => handleLinkClick(e, '/search')}>
        <i className="fa-regular fa-hard-drive"></i>
        <p className='side-text'>Query Dataset</p>
      </div>

      <div className='side-item' onClick={(e) => handleLinkClick(e, '/account')}>
        <i className="fa-regular fa-circle-user"></i>
        <p className='side-text'>Account Settings</p>
      </div>

      <div className='side-item' onClick={(e) => handleLinkClick(e, '/help')}>
        <i className="fa-regular fa-circle-question"></i>
        <p className='side-text'>Help</p>
      </div>

      {loading ? (
        <div className='side-item mt-5 justify-content-center'>
          <Spinner animation="border" as={'span'} style={{ width: '20px', height: '20px' }} />
        </div>
      ) : (
        <div onClick={logoutHandler} className='side-item mt-5'>
          <i className="fa-solid fa-right-from-bracket"></i>
          <p className='side-text'>Log Out</p>
        </div>
      )}

      <br />
      <ProtectedRoute />
    </div>
  )
}

export default SideBar
