import '../styles/home_platform.css'
import '../styles/dashboard.css'
import React, { useState, useEffect } from 'react';
import Header2 from '../components/Header2'
import Footer from '../components/Footer'
import SideBar from '../components/SideBar'
import EditWidgetText from '../components/EditWidgetText'
import SeriesWidget from '../components/SeriesWidget'
import TextWidget from '../components/TextWidget'
import IndicatorWidget from '../components/IndicatorWidget'
import RGL, { WidthProvider, Responsive } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap';


function CreateEditDashboardScreen() {

  const ResponsiveReactGridLayout = WidthProvider(Responsive);

  const [editModal, setEditModal] = useState(false)
  const [selectedWidget, setSelectedWidget] = useState(null)

  const defaultProps = {
    className: "layout",
    rowHeight: 150,
    // onLayoutChange: (layout: any, layouts: any) => {},
    cols: { lg: 6, md: 6, sm: 6, xs: 6, xxs: 6 },
    breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
    containerPadding: [0, 0],
    margin: [20, 20]
  };
  const incrementChar = (c) => {
    setKey(key + 1);
  };

  const [widgets, setWidgets] = useState([
    {type: 'text',
        i: 0,
        x: 0,
        y: 0,
        w: 3,
        h: 1,
        content: {
            body: '<p>Holaaaaaa</p>'
        }
    },
    {type: 'series',
      i: 1,
      x: 3,
      y: 0,
      w: 3,
      h: 2,
      content: {
        "serie_id": 136,
        "series_name": "Test 2",
        "indicator": "Consumption",
        "frequency": "Annual",
        "country_list": [
            {
                "country": "Colombia",
                "color": "#1F6498",
                "type": "line",
                "countryType": "country"
            },
            {
                "country": "Mexico",
                "color": "#f44206",
                "type": "area",
                "countryType": "country"
            }
        ],
        "unit": "% of GDP",
        "xaxis": [
            {
                "id": 0,
                "label": "2002-01-01T00:00:00.000",
                "value": "2002-01-01T00:00:00.000"
            },
            {
                "id": 1,
                "label": "2013-01-01T00:00:00.000",
                "value": "2013-01-01T00:00:00.000"
            }
        ],
        "yaxis": [
            {
                "id": 0,
                "text": "label 1",
                "label": "86",
                "value": "86"
            },
            {
                "id": 1,
                "text": "label 2",
                "label": "90",
                "value": "90"
            }
        ],
        "highlight": [
            {
                "id": 0,
                "x1": {
                    "label": "2017-01-01T00:00:00.000",
                    "value": "2017-01-01T00:00:00.000"
                },
                "x2": {
                    "label": "2019-01-01T00:00:00.000",
                    "value": "2019-01-01T00:00:00.000"
                }
            },
            {
                "id": 1,
                "x1": {
                    "label": "2020-01-01T00:00:00.000",
                    "value": "2020-01-01T00:00:00.000"
                },
                "x2": {
                    "label": "2023-01-01T00:00:00.000",
                    "value": "2023-01-01T00:00:00.000"
                }
            }
        ],
        "data": [
            {
                "period": "1993",
                "value": "79.65139472806304",
                "country": "Mexico",
                "date": "1993-01-01T00:00:00.000"
            },
            {
                "period": "1994",
                "value": "79.21463912342129",
                "country": "Mexico",
                "date": "1994-01-01T00:00:00.000"
            },
            {
                "period": "1995",
                "value": "76.84098081423026",
                "country": "Mexico",
                "date": "1995-01-01T00:00:00.000"
            },
            {
                "period": "1996",
                "value": "76.51296968597167",
                "country": "Mexico",
                "date": "1996-01-01T00:00:00.000"
            },
            {
                "period": "1997",
                "value": "76.73215219197125",
                "country": "Mexico",
                "date": "1997-01-01T00:00:00.000"
            },
            {
                "period": "1998",
                "value": "76.10060317466328",
                "country": "Mexico",
                "date": "1998-01-01T00:00:00.000"
            },
            {
                "period": "1999",
                "value": "76.44211072796502",
                "country": "Mexico",
                "date": "1999-01-01T00:00:00.000"
            },
            {
                "period": "2000",
                "value": "78.04620654474444",
                "country": "Mexico",
                "date": "2000-01-01T00:00:00.000"
            },
            {
                "period": "2001",
                "value": "78.61231499764334",
                "country": "Mexico",
                "date": "2001-01-01T00:00:00.000"
            },
            {
                "period": "2002",
                "value": "79.49733729455826",
                "country": "Mexico",
                "date": "2002-01-01T00:00:00.000"
            },
            {
                "period": "2003",
                "value": "81.14755393247877",
                "country": "Mexico",
                "date": "2003-01-01T00:00:00.000"
            },
            {
                "period": "2004",
                "value": "79.93505859959387",
                "country": "Mexico",
                "date": "2004-01-01T00:00:00.000"
            },
            {
                "period": "2005",
                "value": "81.30297359195293",
                "country": "Mexico",
                "date": "2005-01-01T00:00:00.000"
            },
            {
                "period": "2006",
                "value": "79.1664236890179",
                "country": "Mexico",
                "date": "2006-01-01T00:00:00.000"
            },
            {
                "period": "2007",
                "value": "79.27085727689587",
                "country": "Mexico",
                "date": "2007-01-01T00:00:00.000"
            },
            {
                "period": "2008",
                "value": "80.5339365855098",
                "country": "Mexico",
                "date": "2008-01-01T00:00:00.000"
            },
            {
                "period": "2009",
                "value": "80.50267062548639",
                "country": "Mexico",
                "date": "2009-01-01T00:00:00.000"
            },
            {
                "period": "2010",
                "value": "81.81691049649855",
                "country": "Mexico",
                "date": "2010-01-01T00:00:00.000"
            },
            {
                "period": "2011",
                "value": "81.23074285533205",
                "country": "Mexico",
                "date": "2011-01-01T00:00:00.000"
            },
            {
                "period": "2012",
                "value": "80.61167465881307",
                "country": "Mexico",
                "date": "2012-01-01T00:00:00.000"
            },
            {
                "period": "2013",
                "value": "82.46061153164422",
                "country": "Mexico",
                "date": "2013-01-01T00:00:00.000"
            },
            {
                "period": "2014",
                "value": "82.5623001159973",
                "country": "Mexico",
                "date": "2014-01-01T00:00:00.000"
            },
            {
                "period": "2015",
                "value": "81.05050028149984",
                "country": "Mexico",
                "date": "2015-01-01T00:00:00.000"
            },
            {
                "period": "2016",
                "value": "80.504255861022",
                "country": "Mexico",
                "date": "2016-01-01T00:00:00.000"
            },
            {
                "period": "2017",
                "value": "79.45136805902976",
                "country": "Mexico",
                "date": "2017-01-01T00:00:00.000"
            },
            {
                "period": "2018",
                "value": "78.64730772543304",
                "country": "Mexico",
                "date": "2018-01-01T00:00:00.000"
            },
            {
                "period": "2019",
                "value": "78.71089729611695",
                "country": "Mexico",
                "date": "2019-01-01T00:00:00.000"
            },
            {
                "period": "2020",
                "value": "78.30988004209635",
                "country": "Mexico",
                "date": "2020-01-01T00:00:00.000"
            },
            {
                "period": "2021",
                "value": "79.58743529962496",
                "country": "Mexico",
                "date": "2021-01-01T00:00:00.000"
            },
            {
                "period": "2022",
                "value": "81.60421860623363",
                "country": "Mexico",
                "date": "2022-01-01T00:00:00.000"
            },
            {
                "period": "2023",
                "value": "81.37961258615292",
                "country": "Mexico",
                "date": "2023-01-01T00:00:00.000"
            },
            {
                "period": "2000",
                "value": "85.94117900935593",
                "country": "Colombia",
                "date": "2000-01-01T00:00:00.000"
            },
            {
                "period": "2001",
                "value": "87.09458891038783",
                "country": "Colombia",
                "date": "2001-01-01T00:00:00.000"
            },
            {
                "period": "2002",
                "value": "86.09995801453594",
                "country": "Colombia",
                "date": "2002-01-01T00:00:00.000"
            },
            {
                "period": "2003",
                "value": "84.68376507738347",
                "country": "Colombia",
                "date": "2003-01-01T00:00:00.000"
            },
            {
                "period": "2004",
                "value": "82.88092746992805",
                "country": "Colombia",
                "date": "2004-01-01T00:00:00.000"
            },
            {
                "period": "2005",
                "value": "81.8243687085378",
                "country": "Colombia",
                "date": "2005-01-01T00:00:00.000"
            },
            {
                "period": "2006",
                "value": "81.24521755537172",
                "country": "Colombia",
                "date": "2006-01-01T00:00:00.000"
            },
            {
                "period": "2007",
                "value": "80.77833215870967",
                "country": "Colombia",
                "date": "2007-01-01T00:00:00.000"
            },
            {
                "period": "2008",
                "value": "79.22753769772164",
                "country": "Colombia",
                "date": "2008-01-01T00:00:00.000"
            },
            {
                "period": "2009",
                "value": "80.31955404387007",
                "country": "Colombia",
                "date": "2009-01-01T00:00:00.000"
            },
            {
                "period": "2010",
                "value": "79.69415138036261",
                "country": "Colombia",
                "date": "2010-01-01T00:00:00.000"
            },
            {
                "period": "2011",
                "value": "77.92182196784287",
                "country": "Colombia",
                "date": "2011-01-01T00:00:00.000"
            },
            {
                "period": "2012",
                "value": "79.10809638908526",
                "country": "Colombia",
                "date": "2012-01-01T00:00:00.000"
            },
            {
                "period": "2013",
                "value": "79.63122450437132",
                "country": "Colombia",
                "date": "2013-01-01T00:00:00.000"
            },
            {
                "period": "2014",
                "value": "80.2146537633225",
                "country": "Colombia",
                "date": "2014-01-01T00:00:00.000"
            },
            {
                "period": "2015",
                "value": "83.28664880476016",
                "country": "Colombia",
                "date": "2015-01-01T00:00:00.000"
            },
            {
                "period": "2016",
                "value": "83.6009548705577",
                "country": "Colombia",
                "date": "2016-01-01T00:00:00.000"
            },
            {
                "period": "2017",
                "value": "83.3929586048882",
                "country": "Colombia",
                "date": "2017-01-01T00:00:00.000"
            },
            {
                "period": "2018",
                "value": "83.53983788068537",
                "country": "Colombia",
                "date": "2018-01-01T00:00:00.000"
            },
            {
                "period": "2019",
                "value": "84.44184712678816",
                "country": "Colombia",
                "date": "2019-01-01T00:00:00.000"
            },
            {
                "period": "2020",
                "value": "87.88287291268357",
                "country": "Colombia",
                "date": "2020-01-01T00:00:00.000"
            },
            {
                "period": "2021",
                "value": "88.74818259415721",
                "country": "Colombia",
                "date": "2021-01-01T00:00:00.000"
            },
            {
                "period": "2022",
                "value": "87.86514545265291",
                "country": "Colombia",
                "date": "2022-01-01T00:00:00.000"
            },
            {
                "period": "2023",
                "value": "92.15139890572723",
                "country": "Colombia",
                "date": "2023-01-01T00:00:00.000"
            }
        ],
        "folder_id": 66,
        "folder_name": "Main Folder"
        }
    },
    {type: 'indicator', i: 2, x: 0, y: 1, w: 3, h: 1 },
  ])

  // const [initalLayout, setInitialLayout] = [
  //   { i: 0, x: 0, y: 0, w: 3, h: 1 },
  //   { i: 1, x: 0, y: 1, w: 3, h: 1 },
  //   { i: 2, x: 1, y: 2, w: 1, h: 1 }
  // ];

  // useEffect(() => {
  //   setLayout()
  // }, [])


  const [dashboardName, setDashboardName] = useState('My First Dashboard');
  const [layout, setLayout] = useState(widgets.map(item => {
    return {
      i: item.i,
      x: item.x,
      y: item.y,
      w: item.w,
      h: item.h,
    }
  }))
  let [key, setKey] = useState(4);

  const onLayoutChange = (layout) => {
    console.log("Layout: ", layout)
    // setLayout(layout);
  };

  const dropHandler = (layout, layoutItem, event) => {
    // console.log("Drop Handler called");
    console.log('layout: ', layout)
    console.log('layoutItem: ', layoutItem)
    console.log('type: ', event.dataTransfer.getData('Type'))

    let item = layoutItem;
    item.i = key;
    item.type = event.dataTransfer.getData('Type')
    item.content = {
        body: 'asdf'
    }
    setWidgets([...widgets, item])
    // console.log(JSON.stringify(layoutItem, ["x", "y", "w", "h"], 2));
    setLayout([...layout]);
    incrementChar(key);
  };

  const closeEditHandler = () => {
    setEditModal(false)
  }

  const openEditHandler = (wid) => {
    setSelectedWidget(wid)
    setEditModal(true)
  }

  const updateTextHandler = (wid, text) => {
    console.log("updateeee")
    console.log("wid: ", wid)
    console.log("text: ", text)

    setWidgets(prevState => prevState.map(item => (item.i == wid) ? {...item, content: {body: text}}: item))

    console.log("widgets: ", widgets)
  }

  return (
    <div >
        <Header2 />
        <div className='d-flex' style={{backgroundColor: '#F4F5F7'}}>
            <SideBar />
            <div style={{padding: '3em 5em'}}>

              {/* title */}
              <div className='d-flex align-items-center pb-4'>
                <h1 className='p-title me-4'>{dashboardName}</h1>
                <i className="fa-solid fa-pen pe-4" style={{cursor: 'pointer', color: '#A5A5A5', fontSize: '16px'}}></i>
                <OverlayTrigger
                  placement="right"
                  overlay={
                      <Tooltip id="help-tooltip">
                          Here goes the title.
                      </Tooltip>
                  }
                >
                <i className="fa-solid fa-circle-info" style={{cursor: 'pointer', color: '#A5A5A5', fontSize: '25px'}}></i>
                </OverlayTrigger>
              </div>

              {/* Descripcion */}
              <div className='d-flex align-items-center'>
                <p className='p-paragraph mb-0' style={{color: '#A5A5A5'}}> Here you can write a description about your dashboard. Describe the purpose of this dashboard,
                    its main concept and outline its components. Hightlight the primary objective to ensure users understand its value.
                </p>
                <i className="fa-solid fa-pen me-4" style={{cursor: 'pointer', color: '#A5A5A5', fontSize: '16px'}}></i>
                <OverlayTrigger
                  placement="top"
                  overlay={
                      <Tooltip id="help-tooltip">
                          Here goes the description.
                      </Tooltip>
                  }
                >
                <i className="fa-solid fa-circle-info me-2" style={{cursor: 'pointer', color: '#A5A5A5', fontSize: '25px'}}></i>
                </OverlayTrigger>
              </div>
              <div className='d-flex align-items-center pt-5'>
                <h1 className='p-subtitle'>Widgets</h1>
                <OverlayTrigger
                  placement="right"
                  overlay={
                      <Tooltip id="help-tooltip">
                          Drag and drop one of the following widgets into the dashboard area.
                      </Tooltip>
                  }
                >
                <i className="fa-solid fa-circle-info ps-3" style={{cursor: 'pointer', color: '#A5A5A5', fontSize: '25px'}}></i>
                </OverlayTrigger>
              </div>

              <div className='d-flex justify-content-around'>

                <div
                  className="droppable-element default-widget"
                  draggable={true}
                  unselectable="on"
                // this is a hack for firefox
                // Firefox requires some kind of initialization
                // which we can do by adding this attribute
                // @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
                  onDragStart={e => e.dataTransfer.setData("Type", "text")}
                >
                  <Row>
                    <Col className='default-widget-left'>
                      <p className='default-widget-title'>Text</p>
                      <p className='default-widget-description'>Card with text for descriptions or annotations</p>
                    </Col>
                    <Col>
                      <p className='default-widget-content'>My Text</p>
                    </Col>
                  </Row>
                </div>


                <div className='default-widget'>
                  <Row className='w-100'>
                    <Col className='default-widget-left'>
                      <p className='default-widget-title'>Indicator</p>
                      <p className='default-widget-description'>Card with main figure of one indicator</p>
                    </Col>
                    <Col>
                      <p className='default-widget-content'>Figure</p>
                    </Col>
                  </Row>
                </div>


                <div className='default-widget'>
                  <Row className='w-100'>
                    <Col className='default-widget-left'>
                      <p className='default-widget-title'>Series</p>
                      <p className='default-widget-description'>Card with one specific indicator chart </p>
                    </Col>
                    <Col>
                      <p className='default-widget-content'>My Text</p>
                    </Col>
                  </Row>
                </div>



              </div>
              <div className="border-top my-3 pt-4">

                <ResponsiveReactGridLayout
                  // style={{border: '1px solid blue'}}
                  {...defaultProps}
                  compactType={null}
                  // cols={{lg: 2}}
                  // breakpoints={{lg: 1200}}
                  // style={{ background: "red" }}
                  // layouts={layouts}
                  // onDragStart={(layout, oldItem, newItem, placeholder) => {
                  //   placeholder.style.backgroundColor = 'green'
                  // }}
                  measureBeforeMount={false}
                  isResizable={true}
                  // useCSSTransforms={mounted}
                  // compactType={compactType}
                  preventCollision={true}
                  onLayoutChange={onLayoutChange}
                  // onBreakpointChange={onBreakpointChange}
                  onDrop={dropHandler}
                  isDroppable
                  >
                      {widgets.map(item => (
                        <div key={item.i} data-grid={{x: item.x, y: item.y, w: item.w, h: item.h}}>
                            {item.type == 'series' ? (
                                <SeriesWidget data={item.content} />
                            ): item.type == 'text' ? (
                                <TextWidget wid={item.i} data={item.content} openCallback={openEditHandler} />
                            ): (
                                <IndicatorWidget data={item.content} />
                            )}
                        </div>
                      ))}
                        {/* <div className='bg-danger' key={2} data-grid={{x: 1, y: 1, w: 1, h: 1}}>
                          bbbbb
                        </div> */}
                </ResponsiveReactGridLayout>
              </div>
            </div>
        </div>
        <Footer />

        {editModal && (
            <EditWidgetText closeCallback={closeEditHandler} updateCallback={updateTextHandler} wid={selectedWidget} />
        )}
    </div>
  )
}

export default CreateEditDashboardScreen