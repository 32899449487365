import '../styles/help.css'
import React, { useState } from 'react'

function HelpQuestion({ meta }) {
    const [flag, setFlag] = useState(false)
    const changeHandler = () => {
        // let textContent = document.getElementById('text-content')
        // if(flag) {
        //     textContent.style.display = 'none'
        // }else{
        //     textContent.style.display = 'block'
        // }
        setFlag(!flag)
    }
  return (
    <div className='help-question-container'>
        <div className='d-flex align-items-center justify-content-between p-3'>
            <p onClick={e => changeHandler(flag)} style={{cursor: 'pointer'}} className='help-question-title'>{meta.title}</p>
            <i onClick={e => changeHandler(flag)} style={{cursor: 'pointer', fontSize: '15px', color: '#1E1E1E'}} className={`fa-solid fa-chevron-${flag ? 'up': 'down'} ps-2`}></i>
        </div>
        
        {flag && (
            <p className='help-question-paragraph px-3 pb-3'>{meta.text}</p>
        )}
    </div>
  )
}

export default HelpQuestion
