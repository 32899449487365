import { configureStore, combineReducers } from '@reduxjs/toolkit'

import {
    userLoginReducer,
    userRegisterReducer,
    userDeleteReducer,
    updateNameReducer,
    updatePasswordReducer,
    updateImageReducer,
    googleLoginReducer,
    forgotPasswordReducer,
    changeForgotPasswordReducer,
} from './reducers/userReducers'
import {
    areaListReducer,
    topicListReducer,
    indicatorListReducer,
    countryListReducer,
    cardListReducer,
    cardUpdateReducer,
    selectedIndicatorReducer,
    saveSearchReducer,
    globalTopSearchReducer,
    userTopSearchReducer,
} from './reducers/searchReducer'
import {
    dataListReducer,
    saveSeriesRecucer,
    updateSeriesReducer,
} from './reducers/viewerReducer'
import {
    createFolderReducer,
    listFolderReducer,
    deleteFolderReducer,
    seriesListReducer,
    updateFolderReducer,
    deleteSerieReducer,
    favoriteCountReducer,
    seriesTrackerReducer,
    createTrackerReducer,
    deleteTrackerReducer,
    editTrackerReducer,
    createDashboardReducer,
    getDashboardReducer,
    updateDashboardTitleReducer,
    updateDashboardDescriptionReducer,
    saveDashboardReducer,
    getWidgetsReducer,
    getIndicatorUnitsReducer,
    indicatorTimeframeReducer,
    deleteWidgetReducer,
    serieWidgetReducer,
    dashboardListReducer,
    deleteDashboardReducer,
    dashboardCountReducer,
    dashboardsHomeReducer,
    viewCountReducer,
    updateDashboardImageReducer,
    recentSearchReducer,
} from './reducers/platformReducer'

const reducer = combineReducers({
    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,
    userDelete: userDeleteReducer,
    updateName: updateNameReducer,
    updatePassword: updatePasswordReducer,
    updateImage: updateImageReducer,
    googleLogin: googleLoginReducer,
    forgotPasswordRequest: forgotPasswordReducer,
    changeForgotPasswordRequest: changeForgotPasswordReducer,

    areaList: areaListReducer,
    topicList: topicListReducer,
    indicatorList: indicatorListReducer,
    countryList: countryListReducer,
    cardList: cardListReducer,
    cardUpdate: cardUpdateReducer,
    selectedIndicator: selectedIndicatorReducer,
    saveSearch: saveSearchReducer,
    globalTopSearch: globalTopSearchReducer,
    userTopSearch: userTopSearchReducer,
    // resetSearch: resetSearchReducer,

    dataList: dataListReducer,
    saveSeries: saveSeriesRecucer,
    updateSeries: updateSeriesReducer,

    createFolder: createFolderReducer,
    listFolder: listFolderReducer,
    deleteFolder: deleteFolderReducer,
    seriesList: seriesListReducer,
    updateFolder: updateFolderReducer,
    deleteSerie: deleteSerieReducer,
    favoriteCount: favoriteCountReducer,
    seriesTracker: seriesTrackerReducer,
    createTracker: createTrackerReducer,
    deleteTracker: deleteTrackerReducer,
    editTracker: editTrackerReducer,
    createDashboard: createDashboardReducer,
    getDashboard: getDashboardReducer,
    updateDashboardTitle: updateDashboardTitleReducer,
    updateDashboardDescription: updateDashboardDescriptionReducer,
    saveDashboard: saveDashboardReducer,
    getWidgets: getWidgetsReducer,
    getIndicatorUnits: getIndicatorUnitsReducer,
    indicatorTimeframe: indicatorTimeframeReducer,
    deleteWidget: deleteWidgetReducer,
    serieWidget: serieWidgetReducer,
    dashboardList: dashboardListReducer,
    deleteDashboard: deleteDashboardReducer,
    dashboardCount: dashboardCountReducer,
    dashboardsHome: dashboardsHomeReducer,
    viewCount: viewCountReducer,
    updateDashboardImage: updateDashboardImageReducer,
    recentSearch: recentSearchReducer,
})

const userInfoFromStorage = localStorage.getItem('userInfo') ?
    JSON.parse(localStorage.getItem('userInfo')) : null

export const initialState = {
    userLogin: {userInfo: userInfoFromStorage},
}

const store = configureStore({
    reducer: reducer,
    preloadedState: initialState,
})

export default store
