import React, {useState, useEffect} from 'react'
import { Col, Row } from 'react-bootstrap'
import { prettyNumber } from '../Utilities'

export const LMWDataGet = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      fetch('https://mydata.iadb.org/api/views/tbnd-z94f.json')  // Reemplaza con tu endpoint
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setData(data);  // Guarda los datos en el estado
          setLoading(false);
        })
        .catch(error => {
          setError(error);
          setLoading(false);
        });
    }, []);

    if (loading) {
      return <div>Loading...</div>;
    }

    if (error) {
      return <div>Error: {error.message}</div>;
    }


    const numberOfCountries = data?.metadata?.custom_fields?.["Dataset Summary"]?.["Number of Countries"];
    const numberOfVariables = data?.metadata?.custom_fields?.["Dataset Summary"]?.["Number of Variables"];
    const PeriodCovered = data?.metadata?.custom_fields?.["Data Information"]?.["Time Period Covered"];

    return (
      <div>
        <Row className='py-5'>
          <Col md={3} sm={1} style={{borderRight: '1px solid #D2D2D2'}}>
            <div className='about-content justify-content-center'>
              <div className='about-center'>
                <h1>{prettyNumber(data.viewCount)}</h1>
                <p>Views</p>
              </div>
            </div>
            <div className='about-content justify-content-center'>
              <div className='about-center'>
                <h1>{data.downloadCount}</h1>
                <p>Downloads</p>
              </div>
            </div>
          </Col>
          <Col md={5} sm={1} className='pas-5'>
            <div className='about-content pas-5'>
              <div>
                <img className='pe-4' src="/static/calendar.svg" />
              </div>
              <div>
                <h1>{PeriodCovered}</h1>
                <p>Period Covered</p>
              </div>
            </div>
            <div className='about-content pas-5'>
              <img src="/static/globe.svg" className='pe-4' />
              <div>
                <h1>{numberOfCountries}</h1>
                <p>Countries</p>
              </div>
            </div>
          </Col>
          <Col md={4} sm={1}>
            <div className='about-content'>
              <img src="/static/server.svg" className='pe-4'/>
              <div>
                <h1>{numberOfVariables}</h1>
                <p>Variables</p>
              </div>
            </div>
            <div className='about-content'>
              <img src="/static/database.svg" className='pe-4' />
              <div>
                <h1>Public</h1>
                <p>Access</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
};
