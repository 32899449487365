import React, {useEffect, useState} from 'react'
import { roundy } from './Utilities'
import Chart from 'react-apexcharts';

function IndicatorWidget({editFlag, wid, data, openCallback, deleteCallback}) {

  // console.log('wid: ', wid)
  // console.log('data: ', data)

  return (
    <div className='dwidget-container'>
        
        <div style={{position: 'relative'}} className='h-100'>
          {editFlag && (
            <div style={{position: 'absolute', right: '0'}}>
                <i onMouseDown={e => openCallback(wid)} style={{color: '#808080', cursor: 'pointer'}} className="fa-regular fa-pen-to-square"></i>
                <i onMouseDown={e => deleteCallback(wid)} style={{color: '#808080', cursor: 'pointer'}} className="fa-regular fa-circle-xmark px-2 pt-2"></i>
            </div>
          )}
          {Object.keys(data).length == 0 ? (
            <div onMouseDown={e => openCallback(wid)} style={{color: '#8b8b8b', cursor: 'pointer'}} className='text-center d-flex align-items-center justify-content-center flex-column h-100'>
              Click edit button <br />
              to edit the indicator
              {/* {editFlag && (
                <i onMouseDown={e => openCallback(wid)} style={{color: '#808080', cursor: 'pointer'}} className="fa-regular fa-pen-to-square"></i>
              )} */}
            </div>
          ) : (
            <div className='d-flex flex-column justify-content-center h-100'>
                <p className='dwidget-indicator-title'>{data.indicatorValue?.label}</p>
                {/* <p className='dwidget-indicator-country'>Argentina</p> */}
                <p className='dwidget-indicator-value'>{data.cardValue}</p>
                <p className='dwidget-indicator-unit'>{data.unitValue?.label} ({data.countryValue?.label})</p>
                {/* <p className='dwidget-indicator-time'>{
                  data?.timeframeValue?.label.split('-')[0] + '/' + data?.timeframeValue?.label.split('-')[1]} - 
                  {data?.timeframe2Value?.label.split('-')[0] + '/' + data?.timeframe2Value?.label.split('-')[1]}
                </p> */}
                <p className='dwidget-indicator-time'>
                  {data.timeframeValue && data.frequencyValue.value == 'Annual' && (
                    data.timeframeValue.label.split('-')[0]
                  )}
                  {data.timeframeValue && data.frequencyValue.value == 'Monthly' && (
                    data.timeframeValue.label.split('-')[0] + '/' + data.timeframeValue.label.split('-')[1]
                  )}
                  {data.timeframeValue && data?.frequencyValue.value == 'Quarterly' && (
                    data.timeframeValue.label.split('-')[0] + '/' + data.timeframeValue.label.split('-')[1]
                  )}
                  
                  {data.rangeValue && (
                    ' - '
                  )}
                  
                  {data.timeframe2Value && data.frequencyValue.value == 'Annual' && (
                    data.timeframe2Value.label.split('-')[0]
                  )}
                  {data.timeframe2Value && data.frequencyValue.value == 'Monthly' && (
                    data.timeframe2Value.label.split('-')[0] + '/' + data.timeframe2Value.label.split('-')[1]
                  )}
                  {data.timeframe2Value && data.frequencyValue.value == 'Quarterly' && (
                    data.timeframe2Value.label.split('-')[0] + '/' + data.timeframe2Value.label.split('-')[1]
                  )}
              </p>
            </div>
          )}
        </div>
      
    </div>
  )
}

export default IndicatorWidget
