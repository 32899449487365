export const AREA_LIST_REQUEST = 'AREA_LIST_REQUEST'
export const AREA_LIST_SUCCESS = 'AREA_LIST_SUCCESS'
export const AREA_LIST_FAIL = 'AREA_LIST_FAIL'

export const TOPIC_LIST_REQUEST = 'TOPIC_LIST_REQUEST'
export const TOPIC_LIST_SUCCESS = 'TOPIC_LIST_SUCCESS'
export const TOPIC_LIST_FAIL = 'TOPIC_LIST_FAIL'

export const INDICATOR_LIST_REQUEST = 'INDICATOR_LIST_REQUEST'
export const INDICATOR_LIST_SUCCESS = 'INDICATOR_LIST_SUCCESS'
export const INDICATOR_LIST_FAIL = 'INDICATOR_LIST_FAIL'

export const COUNTRY_LIST_REQUEST = 'COUNTRY_LIST_REQUEST'
export const COUNTRY_LIST_SUCCESS = 'COUNTRY_LIST_SUCCESS'
export const COUNTRY_LIST_FAIL = 'COUNTRY_LIST_FAIL'


export const SEARCH_CARDS_REQUEST = 'SEARCH_CARDS_REQUEST'
export const SEARCH_CARDS_SUCCESS = 'SEARCH_CARDS_SUCCESS'
export const SEARCH_CARDS_FAIL = 'SEARCH_CARDS_FAIL'
export const SEARCH_CARDS_RESET = 'SEARCH_CARDS_RESET'

export const CHANGE_CARD_REQUEST = 'CHANGE_CARD_REQUEST'
export const CHANGE_CARD_SUCCESS = 'CHANGE_CARD_SUCCESS'
export const CHANGE_CARD_FAIL = 'CHANGE_CARD_FAIL'

export const SELECTED_INDICATOR = 'SELECTED_INDICATOR'

export const SAVE_SEARCH_REQUEST = 'SAVE_SEARCH_REQUEST'
export const SAVE_SEARCH_SUCCESS = 'SAVE_SEARCH_SUCCESS'
export const SAVE_SEARCH_FAIL = 'SAVE_SEARCH_FAIL'
export const SAVE_SEARCH_RESET = 'SAVE_SEARCH_RESET'

export const GLOBAL_TOP_SEARCH_REQUEST = 'GLOBAL_TOP_SEARCH_REQUEST'
export const GLOBAL_TOP_SEARCH_SUCCESS = 'GLOBAL_TOP_SEARCH_SUCCESS'
export const GLOBAL_TOP_SEARCH_FAIL = 'GLOBAL_TOP_SEARCH_FAIL'
export const GLOBAL_TOP_SEARCH_RESET = 'GLOBAL_TOP_SEARCH_RESET'

export const USER_TOP_SEARCH_REQUEST = 'USER_TOP_SEARCH_REQUEST'
export const USER_TOP_SEARCH_SUCCESS = 'USER_TOP_SEARCH_SUCCESS'
export const USER_TOP_SEARCH_FAIL = 'USER_TOP_SEARCH_FAIL'
export const USER_TOP_SEARCH_RESET = 'USER_TOP_SEARCH_RESET'
