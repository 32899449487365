export const copyText = (id) => {
    // Obtén el elemento que contiene el texto
    const text = document.getElementById(id).innerText;

    navigator.clipboard.writeText(text).then(() => {
        alert('Texto copiado al portapapeles')
    }).catch(err => {
        console.log("error")
    })
}

export const prettyNumber = (number) => {
    if (isNaN(number)) {
      return "Invalid number";
    }
    return number.toLocaleString('es-ES');
  }

export const roundy = (num, decimals=2) => {
    let dig = Math.pow(10,decimals);
    return Math.round((num + Number.EPSILON) * dig) / dig
}

export const formatDate = (dateString) => {
    const date = new Date(dateString);  // Convierte el string a un objeto Date
    return date.toLocaleDateString('en-US', {
      month: 'long',   // Mes completo
      day: 'numeric',  // Día numérico
      year: 'numeric'  // Año numérico
    });
  }

export const timeAgo = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const seconds = Math.floor((now - date) / 1000);
    const intervals = [
        { label: 'year', seconds: 31536000 },
        { label: 'month', seconds: 2592000 },
        { label: 'week', seconds: 604800 },
        { label: 'day', seconds: 86400 },
        { label: 'hour', seconds: 3600 },
        { label: 'minute', seconds: 60 },
        { label: 'second', seconds: 1 },
    ];

    for (const { label, seconds: interval } of intervals) {
        const count = Math.floor(seconds / interval);
        if (count >= 1) {
            return `${count} ${label}${count > 1 ? 's' : ''} ago`;
        }
    }
    return 'just now';
}