import './styles/general.css'
import { HashRouter as Router, Routes, Route } from 'react-router-dom'
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import ProtectedRoute from './components/ProtectedRoute'
import MainScreen from './screens/MainScreen'
import SearchScreen from './screens/SearchScreen'
import HomeScreen from './screens/HomeScreen'
import DashboardScreen from './screens/DashboardScreen'
import CreateEditDashboardScreen from './screens/CreateEditDashboardScreen'
import DashboardDetailsScreen from './screens/DashboardDetailsScreen'
import FavoriteScreen from './screens/FavoriteScreen'
import AccountScreen from './screens/AccountScreen'
import HelpScreen from './screens/HelpScreen'
import GoogleValidator from './screens/GoogleValidator'
import ResetPasswordScreen from './screens/ResetPasswordForgotScreen'
import AccountActivated from './components/AccountActivated'
import Test from './screens/Test'
// import ViewerScreen from './screens/ViewerScreen'

function App() {
  return (
    <main>
      <Router>
        <Routes>
          <Route path='/' Component={MainScreen} exact />
          <Route path='/search' element={<ProtectedRoute isMandatory={false}><SearchScreen /></ProtectedRoute>} />
          <Route path='/home' element={<ProtectedRoute isMandatory={true}><HomeScreen /></ProtectedRoute>} />
          <Route path='/dashboard' element={<ProtectedRoute isMandatory={true}><DashboardScreen /></ProtectedRoute>} exact />
          <Route path='/dashboard/edit' element={<ProtectedRoute isMandatory={true}><CreateEditDashboardScreen /></ProtectedRoute>} />
          <Route path='/dashboard/:code' element={<ProtectedRoute isMandatory={true}><DashboardDetailsScreen /></ProtectedRoute>} />
          <Route path='/favorite' element={<ProtectedRoute isMandatory={true}><FavoriteScreen /></ProtectedRoute>} />
          <Route path='/account' element={<ProtectedRoute isMandatory={true}><AccountScreen /></ProtectedRoute>} />
          <Route path='/help' element={<ProtectedRoute isMandatory={true}><HelpScreen /></ProtectedRoute>} />
          <Route path='/test' element={<Test />} />

          <Route path='/reset-password/:url_token' element={<ProtectedRoute isMandatory={false}><ResetPasswordScreen /></ProtectedRoute>} />

          <Route path='/activate-account' element={<ProtectedRoute isMandatory={false}><AccountActivated /></ProtectedRoute>} />

          <Route path='/google-validator' element={<GoogleValidator />} />

          {/* <Route path='/viewer' Component={ViewerScreen} /> */}
        </Routes>
      </Router>
    </main>
  );
}

export default App;
