import axios from 'axios'
import {
    CREATE_FOLDER_REQUEST,
    CREATE_FOLDER_SUCCESS,
    CREATE_FOLDER_FAIL,

    FOLDER_LIST_REQUEST,
    FOLDER_LIST_SUCCESS,
    FOLDER_LIST_FAIL,

    DELETE_FOLDER_REQUEST,
    DELETE_FOLDER_SUCCESS,
    DELETE_FOLDER_FAIL,

    GET_SERIES_REQUEST,
    GET_SERIES_SUCCESS,
    GET_SERIES_FAIL,

    UPDATE_FOLDER_REQUEST,
    UPDATE_FOLDER_SUCCESS,
    UPDATE_FOLDER_FAIL,

    DELETE_SERIES_REQUEST,
    DELETE_SERIES_SUCCESS,
    DELETE_SERIES_FAIL,

    FAVORITE_COUNT_REQUEST,
    FAVORITE_COUNT_SUCCESS,
    FAVORITE_COUNT_FAIL,

    GET_SERIES_TRACKER_REQUEST,
    GET_SERIES_TRACKER_SUCCESS,
    GET_SERIES_TRACKER_FAIL,

    CREATE_TRACKER_REQUEST,
    CREATE_TRACKER_SUCESS,
    CREATE_TRACKER_FAIL,

    DELETE_TRACKER_REQUEST,
    DELETE_TRACKER_SUCESS,
    DELETE_TRACKER_FAIL,

    EDIT_TRACKER_REQUEST,
    EDIT_TRACKER_SUCESS,
    EDIT_TRACKER_FAIL,

    CREATE_DASHBOARD_REQUEST,
    CREATE_DASHBOARD_SUCCESS,
    CREATE_DASHBOARD_FAIL,

    GET_DASHBOARD_REQUEST,
    GET_DASHBOARD_SUCCESS,
    GET_DASHBOARD_FAIL,

    UPDATE_DASHBOARD_NAME_REQUEST,
    UPDATE_DASHBOARD_NAME_SUCCESS,
    UPDATE_DASHBOARD_NAME_FAIL,

    UPDATE_DASHBOARD_DESCRIPTION_REQUEST,
    UPDATE_DASHBOARD_DESCRIPTION_SUCCESS,
    UPDATE_DASHBOARD_DESCRIPTION_FAIL,

    SAVE_DASHBOARD_REQUEST,
    SAVE_DASHBOARD_SUCCESS,
    SAVE_DASHBOARD_FAIL,

    GET_WIDGETS_REQUEST,
    GET_WIDGETS_SUCCESS,
    GET_WIDGETS_FAIL,

    GET_INDICATOR_UNITS_REQUEST,
    GET_INDICATOR_UNITS_SUCCESS,
    GET_INDICATOR_UNITS_FAIL,

    GET_INDICATOR_TIMEFRAME_REQUEST,
    GET_INDICATOR_TIMEFRAME_SUCCESS,
    GET_INDICATOR_TIMEFRAME_FAIL,

    DELETE_WIDGET_REQUEST,
    DELETE_WIDGET_SUCCESS,
    DELETE_WIDGET_FAIL,

    GET_WIDGET_SERIE_DATA_REQUEST,
    GET_WIDGET_SERIE_DATA_SUCCESS,
    GET_WIDGET_SERIE_DATA_FAIL,

    GET_DASHBOARD_LIST_REQUEST,
    GET_DASHBOARD_LIST_SUCCESS,
    GET_DASHBOARD_LIST_FAIL,

    DELETE_DASHBOARD_REQUEST,
    DELETE_DASHBOARD_SUCCESS,
    DELETE_DASHBOARD_FAIL,

    DASHBOARD_COUNT_REQUEST,
    DASHBOARD_COUNT_SUCCESS,
    DASHBOARD_COUNT_FAIL,

    GET_DASHBOARD_HOME_REQUEST,
    GET_DASHBOARD_HOME_SUCCESS,
    GET_DASHBOARD_HOME_FAIL,

    VIEW_COUNT_REQUEST,
    VIEW_COUNT_SUCCESS,
    VIEW_COUNT_FAIL,

    UPDATE_DASHBOARD_IMAGE_REQUEST,
    UPDATE_DASHBOARD_IMAGE_SUCCESS,
    UPDATE_DASHBOARD_IMAGE_FAIL,

    GET_RECENT_SEARCH_REQUEST,
    GET_RECENT_SEARCH_SUCCESS,
    GET_RECENT_SEARCH_FAIL,
} from '../constants/platformConstants'

export const createFolder = (folderName, folderDescription) => async (dispatch, getState) => {
    try{
        dispatch({
            type: CREATE_FOLDER_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const {data} = await axios.post(
            '/api/v1/create-folder/',
            {
                folder_name: folderName,
                folder_description: folderDescription,
            },
            config
        )

        dispatch({
            type: CREATE_FOLDER_SUCCESS,
            payload: data,
        })


    }catch(error){
        dispatch({
            type: CREATE_FOLDER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getFolders = () => async (dispatch, getState) => {
    try{
        dispatch({
            type: FOLDER_LIST_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const {data} = await axios.get(
            '/api/v1/get-folders/',
            config
        )

        dispatch({
            type: FOLDER_LIST_SUCCESS,
            payload: data,
        })


    }catch(error){
        dispatch({
            type: FOLDER_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const deleteFolderAction = (id) => async (dispatch, getState) => {
    try{
        dispatch({
            type: DELETE_FOLDER_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const {data} = await axios.delete(
            `/api/v1/delete-folder/${id}`,
            config
        )

        dispatch({
            type: DELETE_FOLDER_SUCCESS,
            payload: data,
        })


    }catch(error){
        dispatch({
            type: DELETE_FOLDER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getSeries = (folder) => async (dispatch, getState) => {
    try{
        dispatch({
            type: GET_SERIES_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const {data} = await axios.get(
            `/api/v1/get-series/${folder}`,
            config
        )

        dispatch({
            type: GET_SERIES_SUCCESS,
            payload: data,
        })


    }catch(error){
        dispatch({
            type: GET_SERIES_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateFolder = (id, name, description) => async (dispatch, getState) => {
    try{
        dispatch({
            type: UPDATE_FOLDER_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const {data} = await axios.put(
            `/api/v1/edit-folder/${id}/`,
            {
                folder_name: name,
                folder_description: description,
            },
            config
        )

        dispatch({
            type: UPDATE_FOLDER_SUCCESS,
        })


    }catch(error){
        dispatch({
            type: UPDATE_FOLDER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const deleteSerie = (serieId, folderId) => async (dispatch, getState) => {
    try{
        dispatch({
            type: DELETE_SERIES_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const {data} = await axios.delete(
            `/api/v1/remove-series/${serieId}/`,
            config
        )

        dispatch({
            type: DELETE_SERIES_SUCCESS
        })


    }catch(error){
        dispatch({
            type: DELETE_SERIES_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getFavoriteCount = () => async (dispatch, getState) => {
    try{
        dispatch({
            type: FAVORITE_COUNT_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const {data} = await axios.get(
            '/api/v1/favorite-series/count/',
            config
        )

        dispatch({
            type: FAVORITE_COUNT_SUCCESS,
            payload: data.count
        })


    }catch(error){
        dispatch({
            type: FAVORITE_COUNT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getDashboardCount = () => async (dispatch, getState) => {
    try{
        dispatch({
            type: DASHBOARD_COUNT_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const {data} = await axios.get(
            '/api/v1/dashboard/count/',
            config
        )

        dispatch({
            type: DASHBOARD_COUNT_SUCCESS,
            payload: data.count
        })


    }catch(error){
        dispatch({
            type: DASHBOARD_COUNT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const getSeriesTracker = () => async (dispatch, getState) => {
    try{
        dispatch({
            type: GET_SERIES_TRACKER_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const {data} = await axios.get(
            'api/v1/home/get-series-tracker/',
            config
        )

        dispatch({
            type: GET_SERIES_TRACKER_SUCCESS,
            payload: data
        })


    }catch(error){
        dispatch({
            type: GET_SERIES_TRACKER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createTracker = (serie, order) => async (dispatch, getState) => {
    try{
        dispatch({
            type: CREATE_TRACKER_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const {data} = await axios.post(
            'api/v1/tracker/create/',
            {
                series: serie,
                order: order
            },
            config
        )

        dispatch({
            type: CREATE_TRACKER_SUCESS,
            // payload: data
        })


    }catch(error){
        dispatch({
            type: CREATE_TRACKER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const deleteTracker = (tracker) => async (dispatch, getState) => {
    try{
        dispatch({
            type: DELETE_TRACKER_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const {data} = await axios.delete(
            `api/v1/tracker/delete/${tracker}/`,
            config
        )

        dispatch({
            type: DELETE_TRACKER_SUCESS,
            // payload: data
        })


    }catch(error){
        dispatch({
            type: DELETE_TRACKER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const editTracker = (tracker, serie) => async (dispatch, getState) => {
    try{
        dispatch({
            type: EDIT_TRACKER_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const {data} = await axios.put(
            `api/v1/tracker/update-series/${tracker}/`,
            {
                series: serie,
            },
            config
        )

        dispatch({
            type: EDIT_TRACKER_SUCESS,
            // payload: data
        })


    }catch(error){
        dispatch({
            type: EDIT_TRACKER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createDashboard = () => async (dispatch, getState) => {
    try{
        dispatch({
            type: CREATE_DASHBOARD_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const {data} = await axios.post(
            'api/v1/dashboard/create/',
            {},
            config
        )

        dispatch({
            type: CREATE_DASHBOARD_SUCCESS,
            payload: data
        })


    }catch(error){
        dispatch({
            type: CREATE_DASHBOARD_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getDashboard = (id) => async (dispatch, getState) => {
    try{
        dispatch({
            type: GET_DASHBOARD_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const {data} = await axios.get(
            `api/v1/dashboard/details/${id}/`,
            config
        )

        dispatch({
            type: GET_DASHBOARD_SUCCESS,
            payload: data
        })


    }catch(error){
        dispatch({
            type: GET_DASHBOARD_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateDashboardTitle = (id, name) => async (dispatch, getState) => {
    try{
        dispatch({
            type: UPDATE_DASHBOARD_NAME_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const {data} = await axios.patch(
            `api/v1/dashboard/update-title/${id}/`,
            {
                dashboard_name: name,
            },
            config
        )

        dispatch({
            type: UPDATE_DASHBOARD_NAME_SUCCESS,
            payload: data
        })


    }catch(error){
        dispatch({
            type: UPDATE_DASHBOARD_NAME_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateDashboardDescription = (id, description) => async (dispatch, getState) => {
    try{
        dispatch({
            type: UPDATE_DASHBOARD_DESCRIPTION_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const {data} = await axios.patch(
            `api/v1/dashboard/update-description/${id}/`,
            {
                dashboard_description: description,
            },
            config
        )

        dispatch({
            type: UPDATE_DASHBOARD_DESCRIPTION_SUCCESS,
            payload: data
        })


    }catch(error){
        dispatch({
            type: UPDATE_DASHBOARD_DESCRIPTION_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const saveDashboard = (newWidgets, updateWidgets) => async (dispatch, getState) => {
    try{
        dispatch({
            type: SAVE_DASHBOARD_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const {data} = await axios.post(
            `api/v1/dashboard/widgets/bulk/`,
            {
                new: newWidgets,
                update: updateWidgets,
            },
            config
        )

        dispatch({
            type: SAVE_DASHBOARD_SUCCESS,
            // payload: data
        })


    }catch(error){
        dispatch({
            type: SAVE_DASHBOARD_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getWidgets = (id) => async (dispatch, getState) => {
    try{
        dispatch({
            type: GET_WIDGETS_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const {data} = await axios.get(
            `api/v1/dashboard/${id}/widgets/`,
            config
        )

        dispatch({
            type: GET_WIDGETS_SUCCESS,
            payload: data
        })


    }catch(error){
        dispatch({
            type: GET_WIDGETS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getIndicatorUnits = (indicator, country) => async (dispatch, getState) => {
    try{
        dispatch({
            type: GET_INDICATOR_UNITS_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const {data} = await axios.post(
            `api/v1/dashboard/widgets/data/indicator-unit/`,
            {
                country: country,
                indicator: indicator,
            },
            config
        )

        dispatch({
            type: GET_INDICATOR_UNITS_SUCCESS,
            payload: data
        })


    }catch(error){
        dispatch({
            type: GET_INDICATOR_UNITS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getIndicatorTimeframe = (indicator, country, unit, frequency) => async (dispatch, getState) => {
    try{
        dispatch({
            type: GET_INDICATOR_TIMEFRAME_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const {data} = await axios.post(
            `api/v1/dashboard/widgets/data/indicator-data/`,
            {
                country: country,
                indicator: indicator,
                unit: unit,
                frequency: frequency,
            },
            config
        )

        dispatch({
            type: GET_INDICATOR_TIMEFRAME_SUCCESS,
            payload: data
        })


    }catch(error){
        dispatch({
            type: GET_INDICATOR_TIMEFRAME_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const deleteWidget = (did, wid) => async (dispatch, getState) => {
    try{
        dispatch({
            type: DELETE_WIDGET_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const {data} = await axios.delete(
            `api/v1/dashboard/${did}/widgets/delete/${wid}/`,
            // {
            //     widget_ids: [wid],
            // },
            config
        )

        dispatch({
            type: DELETE_WIDGET_SUCCESS,
        })


    }catch(error){
        dispatch({
            type: DELETE_WIDGET_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getSerieWidget = (sid) => async (dispatch, getState) => {
    try{
        dispatch({
            type: GET_WIDGET_SERIE_DATA_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const {data} = await axios.get(
            `api/v1/dashboard/widgets/data/series/${sid}/`,
            config
        )

        dispatch({
            type: GET_WIDGET_SERIE_DATA_SUCCESS,
            payload: data,
        })


    }catch(error){
        dispatch({
            type: GET_WIDGET_SERIE_DATA_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getDashboardList = () => async (dispatch, getState) => {
    try{
        dispatch({
            type: GET_DASHBOARD_LIST_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const {data} = await axios.get(
            `api/v1/dashboard/list/`,
            config
        )

        dispatch({
            type: GET_DASHBOARD_LIST_SUCCESS,
            payload: data,
        })


    }catch(error){
        dispatch({
            type: GET_DASHBOARD_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const deleteDashboard = (did) => async (dispatch, getState) => {
    try{
        dispatch({
            type: DELETE_DASHBOARD_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const {data} = await axios.delete(
            `api/v1/dashboard/delete/${did}/`,
            config
        )

        dispatch({
            type: DELETE_DASHBOARD_SUCCESS,
            // payload: data,
        })


    }catch(error){
        dispatch({
            type: DELETE_DASHBOARD_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getHomeDashboards = () => async (dispatch, getState) => {
    try{
        dispatch({
            type: GET_DASHBOARD_HOME_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const {data} = await axios.get(
            `/api/v1/dashboard/list/top/`,
            config
        )

        dispatch({
            type: GET_DASHBOARD_HOME_SUCCESS,
            payload: data,
        })


    }catch(error){
        dispatch({
            type: GET_DASHBOARD_HOME_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateViewCount = (code) => async (dispatch, getState) => {
    try{
        dispatch({
            type: VIEW_COUNT_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const {data} = await axios.post(
            `/api/v1/dashboard/update-viewcount/`,
            {
                code: code,
            },
            config
        )

        dispatch({
            type: VIEW_COUNT_SUCCESS,
            // payload: data,
        })


    }catch(error){
        dispatch({
            type: VIEW_COUNT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateDashboardImage = (did, image) => async (dispatch, getState) => {
    try{
        dispatch({
            type: UPDATE_DASHBOARD_IMAGE_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const {data} = await axios.put(
            `/api/v1/dashboard/${did}/update-image/`,
            {
                image: image
            },
            config
        )

        dispatch({
            type: UPDATE_DASHBOARD_IMAGE_SUCCESS,
            // payload: data,
        })


    }catch(error){
        dispatch({
            type: UPDATE_DASHBOARD_IMAGE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getRecentSearch = () => async (dispatch, getState) => {
    try{
        dispatch({
            type: GET_RECENT_SEARCH_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const {data} = await axios.get(
            `/api/v1/series/user-top-indicators/`,
            config
        )

        dispatch({
            type: GET_RECENT_SEARCH_SUCCESS,
            payload: data,
        })


    }catch(error){
        dispatch({
            type: GET_RECENT_SEARCH_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}