import '../styles/favorite.css'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Header2 from '../components/Header2'
import Footer from '../components/Footer'
import SideBar from '../components/SideBar'
import Message from '../components/Message'
import SideResponsiveOpen from '../components/SideResponsiveOpen'
import DeleteFavoriteModal from '../components/DeleteFavoriteModal'
import DeleteSerieModal from '../components/DeleteSerieModal'
import EditFavoriteModal from '../components/EditFavoriteModal'
import MessageModal from '../components/MessageModal'
import ViewerModal from '../components/ViewerModal'
import AddFolderModal from '../components/AddFolderModal'
import { Row, Col, Dropdown, Spinner } from 'react-bootstrap'
import {
  createFolder,
  getFolders,
  getSeries,
} from '../actions/platformActions'
import { 
  CREATE_FOLDER_RESET,
  DELETE_SERIES_RESET,
  DELETE_FOLDER_RESET,
  UPDATE_FOLDER_RESET,
} from '../constants/platformConstants'
import { SAVE_SERIES_RESET } from '../constants/viewerConstants'
import { useNavigate } from 'react-router-dom'

function FavoriteScreen() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  
  const [createFolderName, setCreateFolderName] = useState('')
  const [createFolderDescription, setCreateFolderDescription] = useState('')
  const [selectedFolder, setSelectedFolder] = useState('')
  const [updateFolder, setUpdateFolder] = useState('')

  const [selectedMeta, setSelectedMeta] = useState(null)
  const [selectedName, setSelectedName] = useState(null)

  const [updateMeta, setUpdateMeta] = useState(null)
  const [deleteSerieMeta, setDeleteSerieMeta] = useState(null)

  const [addFolderModal, setAddFolderModal] = useState(false)  
  const [deleteFolderModal, setDeleteFolderModal] = useState(false)
  const [deleteSerieModal, setDeleteSerieModal] = useState(false)
  const [editFolderModal, setEditFolderModal] = useState(false)
  const [viewerModal, setViewerModal] = useState(false)
  const [messageModal, setMessageModal] = useState(false)

  const [modalTitle, setModalTitle] = useState('')
  const [modalMessage, setModalMessage] = useState('')

  const [viewerMeta, setViewerMeta] = useState(null)
  const [serieData, setSeriesData] = useState(null)

  
  useEffect(() => {
    dispatch(getFolders())
  }, [])
  
  const folderInfo = useSelector(state => state.listFolder)
  const {loading: loadingFolder, error: errorFolder, folderList} = folderInfo

  // const deleteFolderMessage = `Are you sure you want to delete the folder called: ${folderList?.filter(item => item.id == updateFolder)[0]?.folder_name}?`
  const deleteFolderMessage = `Are you sure you want to delete the folder called: ${updateFolder.folder_name}?`
  const deleteSerieMessage = 'Are you sure you want to remove this series from the folder?'

  useEffect(() => {
    if(folderList){
      const main = folderList.filter(item => item.folder_name == 'Main Folder')[0]
      setSelectedFolder(main)
      setUpdateFolder(main)
    }
  }, [folderInfo])

  useEffect(() => {
    if(selectedFolder && selectedFolder != ''){
      dispatch(getSeries(selectedFolder.id))
    }
  }, [selectedFolder])

  const seriesInfo = useSelector(state => state.seriesList)
  let {loading: loadingSeries, error: errorSeries, listSeries} = seriesInfo

  const [finalSeries, setFinalSeries] = useState(listSeries)

  useEffect(() => {
    setFinalSeries(listSeries)
  }, [listSeries])

  const openDeleteFolderModal = () => {
    setDeleteFolderModal(true)
  }
  const openDeleteSerieModal = (serieId) => {
    // console.log('id: ', serieId)
    // console.log('folder: ', selectedFolder)
    setDeleteSerieMeta({
      id: serieId,
      folder: selectedFolder.id
    })
    setDeleteSerieModal(true)
  }
  const openEditFolderModal = () => {
    // console.log('updateFolder: ', updateFolder)
    setUpdateMeta({
      id: updateFolder.id,
      name: updateFolder.folder_name,
      description: updateFolder.folder_description,
    })

    setEditFolderModal(true)
  }

  const closeDeleteFolderModal = (isDeleted) => {
    setDeleteFolderModal(false)
    if(isDeleted){
      setModalTitle('Deleted')
      setModalMessage(`The folder has been successfully deleted.`)
      setMessageModal(true)
      dispatch(getFolders())
      dispatch({
        type: DELETE_FOLDER_RESET,
      })
    }
  }
  const closeDeleteSerieModal = (isDeleted) => {
    setDeleteSerieModal(false)
    if(isDeleted) {
      setModalTitle('Deleted')
      setModalMessage('The serie has been successfully deleted.')
      setMessageModal(true)
      dispatch(getSeries(selectedFolder.id))
      dispatch({type: DELETE_SERIES_RESET})
    }
  }
  const closeEditFolderModal = (isDeleted) => {
    setEditFolderModal(false)
    if(isDeleted){
      setModalTitle('Updated')
      setModalMessage(`The folder named "${updateFolder.folder_name}" has been successfully updated.`)
      setMessageModal(true)
      dispatch({
        type: UPDATE_FOLDER_RESET,
      })
      dispatch(getFolders())
    }
  }

  const createInfo = useSelector(state => state.createFolder)
  const {loading, error, success} = createInfo

  const createFolderHandler = () => {
    dispatch(createFolder(createFolderName, createFolderDescription))
  }
  useEffect(() => {
    if(success){
      setModalTitle('Success')
      setModalMessage(`The folder named "${createFolderName}" has been successfully created.`)
      setMessageModal(true)

      setCreateFolderName('')
      setCreateFolderDescription('')
      dispatch(getFolders())
      dispatch({
        type: CREATE_FOLDER_RESET,
      })
    }
  }, [createInfo])

  const changeFolderHandler = (folder) => {
    // console.log("folder: ", folder)
    setSelectedFolder(folder)
  }

  const changeUpdateFolderHandler = (folderId) => {
    const temp = folderList.filter(item => item.id == folderId)[0]
    setUpdateFolder(temp);
  }

  const openViewerSeries = (serie) => {
    // console.log("serie: ", serie)
    setViewerMeta(serie.meta_data)
    setSeriesData({
      id: serie.id,
      name: serie.series_name,
      folder_id: selectedFolder.id,
      folder_name: selectedFolder.folder_name,
    })
    setViewerModal(true)
  }

  // useEffect(() => {
  //   if(viewerMeta && viewerMeta.area) {
  //     console.log("serie: ", viewerMeta)
  //     // setViewerModal(true)
  //   }
  // }, [viewerMeta])

  const closeViewerHandler = () => {
    dispatch(getSeries(selectedFolder.id))
    setViewerModal(false)
  }

  const addSerieHandler = () => {
    navigate('/search')
  }

  const addFolderHandler = (item) => {
    
    
    
        // dispatch(getFolders())
        // let finalMeta = JSON.parse(JSON.stringify(metaSender))
        // finalMeta.chart = {
        //     unit: unitValue,
        //     frequency: frequencyValue,
        //     country: countryValue,
        //     legend: legendValue,
        //     metrics: metricValue,
        //     xaxis: xAnnotationValue,
        //     yaxis: yAnnotationValue,
        //     highlight: highlightValue
        // }
        dispatch({
          type: SAVE_SERIES_RESET,
        })
        setSelectedMeta(item.meta_data)
        setSelectedName(item.series_name)
        // setMetaSender(finalMeta)
        setAddFolderModal(true)

}
  const folderOptions = folderList?.filter(item => item.id != selectedFolder.id)?.map(item => ({
    value: item.id,
    label: item.folder_name,
  }))

const closeFolderModal = () => {
  setAddFolderModal(false)
}

const closeMessageHandler = () => {
  setMessageModal(false)
  setModalTitle('')
  setModalMessage('')
}

const orderBy = (column, flag) => {
  // console.log("column: ", column)
  // console.log("flag: ", flag)
  if(flag) {
    setFinalSeries(listSeries.sort((a,b) => a['series_name'] - b['series_name']))
    // console.log("finalSeries: ", finalSeries)
  }else{
    setFinalSeries(listSeries.sort((a,b) => b['series_name'] - a['series_name']))
    // console.log("finalSeries: ", finalSeries)
  }
}

  return (
        <div className='h-100 d-flex flex-column'>
          <Header2 />
          <div className='d-flex' style={{backgroundColor: '#F4F5F7', flex: '1'}}>
              <SideBar />
              <div className='favorite-main-container'>
                <h1 className='p-title pb-4'>Favorites</h1>

                <p className='p-paragraph'>Here you can easily access your saved series and keep track of all your preferred indicators, ensuring you have all your important data at your fingertips for quick and efficient monitoring.</p>

                <div className='d-flex align-items-center justify-content-between pt-5 pb-3'>
                  {/* <div className='d-flex align-items-center'>
                    <p className='p-subtitle m-0 pe-4'>Series for Debt Analysis</p>
                    <i style={{fontSize: '25px'}} className="fa-solid fa-chevron-down"></i>
                  </div> */}
                  <div>
                    <Dropdown>
                      <Dropdown.Toggle className='favorite-tester p-0'>
                        {selectedFolder.folder_name}
                        <i className="ms-3 fa-solid fa-chevron-down"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{fontSize: '14px'}}>
                        {folderList && folderList.map(folder => (
                          <Dropdown.Item onClick={e => changeFolderHandler(folder)} key={folder.id}>
                            {folder.folder_name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <div style={{border: '1px solid #7F7F7F', borderRadius: '7px'}} className='d-flex align-items-center px-4 py-2 d-none'>
                    <i style={{color: '#606060'}} className="fa-solid fa-filter"></i>
                    <p style={{color: '#606060'}} className='p-paragraph ps-3 m-0'>Filter</p>
                  </div>
                </div>

                

                <p className='p-paragraph'>{selectedFolder.folder_description}</p>

                {loadingSeries ? (
                  <div className='d-flex justify-content-center'>
                    <Spinner animation="border" as={'span'} style={{width: '20px', height: '20px'}}/>
                  </div>
                ): errorSeries ? (
                  <Message variant={'danger'}>{errorSeries}</Message>
                ): (
                  <div className='table-container'>
                    <table className='favorite-table mb-5'>
                      <thead>
                        <tr>
                          <th></th>
                          <th>
                            Name
                            <i onClick={e => orderBy('series_name', false)} className="ms-5 fa-solid fa-caret-down"></i>
                          </th>
                          <th>
                            Indicator
                            <i className="ms-5 fa-solid fa-caret-down"></i>
                          </th>
                          <th>
                            Area
                            <i className="ms-5 fa-solid fa-caret-down"></i>
                          </th>
                          <th>
                            Topic
                            <i className="ms-5 fa-solid fa-caret-down"></i>
                          </th>
                          <th>
                            Unit
                            <i className="ms-5 fa-solid fa-caret-down"></i>
                          </th>
                          <th>
                            Frequency
                            <i className="ms-5 fa-solid fa-caret-down"></i>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {finalSeries && finalSeries?.map(item => (
                          <tr key={item.id}>
                            <td className='d-flex flex-nowrap align-items-center'>
                              <i onClick={e => openDeleteSerieModal(item.id)} className="table-icon fa-regular fa-trash-can"></i>
                              <i onClick={e => openViewerSeries(item)} className="table-icon fa-solid fa-arrow-trend-up"></i>
                              <i onClick={e => addFolderHandler(item)} className="table-icon fa-solid fa-folder-plus pe-3"></i>
                            </td>
                            <td>{item.series_name}</td>
                            <td>{item.meta_data.indicator}</td>
                            <td>{item.meta_data.area.split('_')[1]}</td>
                            <td>{item.meta_data.topic.split('_')[1]}</td>
                            <td>{item.meta_data.chart.unit.label}</td>
                            <td className='text-center'>{item.meta_data.chart.frequency.label}</td>
                          </tr>
                        ))}
                        <tr style={{border: 'none'}}>
                          <td onClick={addSerieHandler} colSpan={7} className='text-center last-row'>
                            <i className="fa-solid fa-plus pe-2"></i>
                            Add new series
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}


                

                

                <p className='p-subtitle'>Folders</p>

                <p className='p-paragraph'>Edit your folders and create new folders to keep your series organized.</p>
                

                <Row>
                  <Col>
                    <div style={{maxWidth: '340px'}} className='pt-4'>
                      <span className='p-label-2'>Select Folder:</span>
                      <select value={updateFolder.id} onChange={e => changeUpdateFolderHandler(e.target.value)} className='p-select mt-2 mb-4 w-100'>
                        {folderList && folderList.map(folder => (
                          <option key={folder.id} value={folder.id}>{folder.folder_name}</option>
                        ))}
                      </select>

                      <div className='d-flex justify-content-between w-100'>
                        <div onClick={openDeleteFolderModal} className={`p-button me-3 ${(updateFolder && updateFolder.folder_name == 'Main Folder') ? 'button-disabled': ''}`}>Delete Folder</div>
                        <div onClick={openEditFolderModal} className='p-button'>Edit Folder</div>
                      </div>
                    </div>
                  
                  </Col>
                  <Col>
                    <div style={{maxWidth: '305px'}} className='d-flex flex-column pt-4'>
                        <span className='p-label-2'>Create new folder:</span>
                        {loading ? (
                          <div className='d-flex justify-content-center align-items-center pt-4'>
                            <Spinner animation="border" as={'span'} style={{width: '20px', height: '20px'}}/>
                          </div>
                        ): (
                          <>
                            <input value={createFolderName} onChange={e => setCreateFolderName(e.target.value)} className='p-input my-2' placeholder='Folder name' type="text" />
                            <textarea value={createFolderDescription} onChange={e => setCreateFolderDescription(e.target.value)} className='p-text-area mb-2' rows='4' placeholder='Folder description' name="" id=""></textarea>
                            <div onClick={createFolderHandler} className={`p-button my-1 ${(createFolderName && createFolderDescription && createFolderName != '' && createFolderDescription != '') ? '': 'button-disabled'}`}>Create Folder</div>
                          </> 
                        )}
                        {error && (
                          <Message variant={'danger'}>{error}</Message>
                        )}
                    </div>
                  </Col>

                </Row>

                
                
              </div>
          </div>

          <SideResponsiveOpen />
          <Footer />

          {addFolderModal && (
            // <AddFolderModal closeCallback={closeFolderModal} meta={metaSender} folders={folderOptions} />
            <AddFolderModal closeCallback={closeFolderModal} meta={selectedMeta} folders={folderOptions} name={selectedName} />
          )}

          {deleteSerieModal && (
            <DeleteSerieModal meta={deleteSerieMeta} message={deleteSerieMessage} closeCallback={closeDeleteSerieModal} />
          )}
          {deleteFolderModal && (
            <DeleteFavoriteModal message={deleteFolderMessage} folder={updateFolder.id} closeCallback={closeDeleteFolderModal} />
          )}

          {editFolderModal && (
            <EditFavoriteModal meta={updateMeta} closeCallback={closeEditFolderModal} />
          )}

          {viewerModal && viewerMeta && (
            <ViewerModal meta={viewerMeta} closeCallback={closeViewerHandler} isSaved={serieData} />
          )}

          {messageModal && (
            <MessageModal closeCallback={closeMessageHandler} title={modalTitle} message={modalMessage} />
          )}
        </div>
  )
}

export default FavoriteScreen
