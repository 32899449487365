import '../styles/modals.css'
import React, { useEffect, useState } from 'react'
import  Select  from 'react-select'
import {
  saveSeries,
} from '../actions/viewerActions'
import { useDispatch, useSelector } from 'react-redux'
import { SAVE_SERIES_RESET } from '../constants/viewerConstants'
import Message from './Message'
import { Spinner } from 'react-bootstrap'

function AddFolderModal({closeCallback, folders, meta, name = null}) {

  const dispatch = useDispatch()

    const [folderValue, setFolderValue] = useState(null)
    const [seriesName, setSeriesName] = useState(name)

    // useEffect(()=> {
    //     console.log("meta: ", meta)
    // }, [])

    const addHandler = (option) => {
        setFolderValue(option)
    }

    const saveInfo = useSelector(state => state.saveSeries)
    const {loading, error, success} = saveInfo

    useEffect(() => {
      if(success){
        closeCallback()
      }
    }, [saveInfo])

    const sendHandler = () => {
      const folderIds = [... folderValue].map(item => item.value)
      if(seriesName){
        dispatch(saveSeries(folderIds, meta, seriesName))
      } else{
        dispatch(saveSeries(folderIds, meta, meta.indicator))
      }
      // console.log("folderIds: ", folderIds)
      // console.log("meta: ", meta)
    }
  return (
    <div className='modal-container'>
        <div style={{width: '25em'}} className='modal-window px-5 pb-5 pt-4 d-flex flex-column align-items-center'>

            <i onClick={closeCallback} style={{cursor: 'pointer'}} className="fa-solid fa-xmark text-secondary fs-4 ms-auto"></i>
           <h1 style={{color: '#313A52'}} className='bold-title fs-1'>Folders</h1>

           <p className='p-paragraph'>
                Select the folder where you want to add this series
            </p>

            <Select 
                className="custom-select-container"
                classNamePrefix="custom-select"
                placeholder={'Select folders'}
                isMulti={true}
                value={folderValue}
                options={folders}
                onChange={option => addHandler(option)}
            />
           
          
            
            {loading ? (
              <div className='d-flex justify-content-center align-items-center pt-3'>
                <Spinner animation="border" as={'span'} style={{width: '20px', height: '20px'}}/>
              </div>
            ): error ? (
              <>
                <Message variant='danger'>{error}</Message>
                <div onClick={sendHandler} style={{width: '100%'}} className='login-button mt-3'>Add to Folders</div>
              </>
            ): (
              <div onClick={sendHandler} style={{width: '100%'}} className='login-button mt-3'>Add to Folders</div>
            )}

              
        </div>
      </div>
  )
}

export default AddFolderModal
