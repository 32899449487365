import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
    formatDate,
  } from '../components/Utilities'

function DashboardListItem({data}) {

    const navigate = useNavigate()

    const redirectHandler = () => {
        navigate(`/dashboard/${data.code_id}`)
    }
  return (
    <div className='dashboard-item-list'>
        <div className='dashboard-item-image'>
        <img style={{height: '3em', width: 'auto'}} src={`/static/dashboard-icons/${data.image}`} />
        </div>

        <div className='dashboard-item-meta'>
            <p className='dashboard-item-date'>Created on {formatDate(data.created_at)}</p>

            <p onClick={redirectHandler} className='dashboard-item-title'>{data.dashboard_name}</p>
            <p className='dashboard-item-description'>{data.dashboard_description}</p>
        </div>
    </div>

  )
}

export default DashboardListItem
