import React from 'react'
import { Row, Col } from 'react-bootstrap'
import '../styles/general.css'

function Footer() {
  return (
    <div>

      {/* ########### FOOTER CARD ######### */}
      <div className='footer p-5' style={{backgroundColor: '#D5D5D5'}}>
          <p className='m-0'>
            <b><a href="https://creativecommons.org/licenses/by-nc-nd/3.0/igo/legalcode" target='_blank'>Creative Commons Attribution | Noncommercial | No Derivative Works 3.0 IGO</a></b> <br />
            <b>Created by:</b> Research Department and Fiscal Management Division - IDB - Latin Macro Watch - FISLAC <br />
            <b>Disclaimer:</b> The results offered in this database/dataset are those compiled by the authors and do not necessarily reflect the <br /> views of the Inter-American Development Bank, its Board of Directors, or the countries they represent.
          </p>
      </div>

    </div>
  )
}

    

export default Footer
