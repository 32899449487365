import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Row, Col } from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import {
  updateImage,
  refreshUser,
} from '../actions/userActions'
import { Spinner } from 'react-bootstrap'
import '../styles/modals.css'
import axios from 'axios'

function ChangeAvatarModal({closeCallback, isSaved = false}) {

  const dispatch = useDispatch()
  const [selectedImage, setSelectedImage] = useState(null)
  const userLogin = useSelector(state => state.userLogin)
  const {userInfo} = userLogin

  const submitHandler = () => {
    dispatch(updateImage(selectedImage))
  }

  const imageInfo = useSelector(state => state.updateImage)
  const {loading, error, success} = imageInfo

  useEffect(() => {
    if(success){
      const updatedImage = selectedImage
      const localUser = localStorage.getItem('userInfo');
      let userObject = JSON.parse(localUser)
      userObject.profile_image = updatedImage
      dispatch(refreshUser(userObject))
      closeCallback(true)
    }
  }, [imageInfo])

  return (
    <div className='modal-container'>
        <div style={{maxWidth: '25em'}} className='modal-window px-5 pb-5 pt-4 d-flex flex-column align-items-center'>
            <i onClick={e => closeCallback(false)} style={{cursor: 'pointer'}} className="fa-solid fa-xmark text-secondary fs-4 ms-auto"></i>
            {/* <img src="/static/forgot.svg" style={{height: '5em', width: 'auto'}} /> */}
            <h1 style={{color: '#313A52'}} className='bold-title fs-1 p-3'>Avatar</h1>
            <p className='chart-p text-center'>Select one of the following avatars to personalize your account</p>
            <div className='d-flex flex-wrap justify-content-center avatar-container'>
              {[... Array(16).keys()].map(item => (
                <img key={item} onClick={e => setSelectedImage(`user_${item}.png`)} className={`${(userInfo.profile_image == `user_${item}.png`) ? 'user-avatar': ''}   ${selectedImage == `user_${item}.png` ? 'active-avatar': ''}`} src={`/static/account/user_${item}.png`} alt="" />
              ))}
              
            </div>
            
            {loading ? (
              <div className='login-button mt-4'>
                <Spinner animation="border" variant='light' as={'span'} style={{width: '20px', height: '20px'}}/>
              </div>
            ) : (
              <div onClick={submitHandler} className={`login-button mt-4 ${(selectedImage && selectedImage != '') ? '': 'button-disabled'}`}>Submit</div>
            )}

            {error && (
              <Message variant={'danger'}>{error}</Message>
            )}
        </div>
    </div>

  )
}

export default ChangeAvatarModal
