export const ACCESS_TOKEN = "access";
export const REFRESH_TOKEN = "refresh";

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'

export const GOOGLE_LOGIN = 'GOOGLE_LOGIN'
export const GOOGLE_RESET = 'GOOGLE_RESET'

export const USER_UPDATE_DATA = 'USER_UPDATE_DATA'
// export const USER_GET_DATA = 'USER_GET_NAME_SUCCESS'
// export const USER_GET_DATA = 'USER_GET_NAME_FAIL'

export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST'
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS'
export const USER_LOGOUT_FAIL = 'USER_LOGOUT_FAIL'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const USER_PASSWORD_CHANGE_REQUEST = 'USER_PASSWORD_CHANGE_REQUEST'
export const USER_PASSWORD_CHANGE_SUCCESS = 'USER_PASSWORD_CHANGE_SUCCESS'
export const USER_PASSWORD_CHANGE_FAIL = 'USER_PASSWORD_CHANGE_FAIL'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'
export const USER_DELETE_RESET = 'USER_DELETE_RESET'

export const UPDATE_NAME_REQUEST = 'UPDATE_NAME_REQUEST'
export const UPDATE_NAME_SUCCESS = 'UPDATE_NAME_SUCCESS'
export const UPDATE_NAME_FAIL = 'UPDATE_NAME_FAIL'
export const UPDATE_NAME_RESET = 'UPDATE_NAME_RESET'

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL'
export const UPDATE_PASSWORD_RESET = 'UPDATE_PASSWORD_RESET'

export const UPDATE_IMAGE_REQUEST = 'UPDATE_IMAGE_REQUEST'
export const UPDATE_IMAGE_SUCCESS = 'UPDATE_IMAGE_SUCCESS'
export const UPDATE_IMAGE_FAIL = 'UPDATE_IMAGE_FAIL'
export const UPDATE_IMAGE_RESET = 'UPDATE_IMAGE_RESET'

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL'
export const FORGOT_PASSWORD_RESET = 'FORGOT_PASSWORD_RESET'

export const CHANGE_FORGOT_PASSWORD_REQUEST = 'CHANGE_FORGOT_PASSWORD_REQUEST'
export const CHANGE_FORGOT_PASSWORD_SUCCESS = 'CHANGE_FORGOT_PASSWORD_SUCCESS'
export const CHANGE_FORGOT_PASSWORD_FAIL = 'CHANGE_FORGOT_PASSWORD_FAIL'
export const CHANGE_FORGOT_PASSWORD_RESET = 'CHANGE_FORGOT_PASSWORD_RESET'

export const ACTIVE_TOKEN_FORGOT_PASSWORD_REQUEST = 'ACTIVE_TOKEN_FORGOT_PASSWORD_REQUEST'
export const ACTIVE_TOKEN_FORGOT_PASSWORD_SUCCESS = 'ACTIVE_TOKEN_FORGOT_PASSWORD_SUCCESS'
export const ACTIVE_TOKEN_FORGOT_PASSWORD_FAIL = 'ACTIVE_TOKEN_FORGOT_PASSWORD_FAIL'
export const ACTIVE_TOKEN_FORGOT_PASSWORD_RESET = 'ACTIVE_TOKEN_FORGOT_PASSWORD_RESET'