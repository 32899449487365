import { useDispatch, useSelector } from 'react-redux'
import '../styles/modals.css'
import React, { useEffect, useState } from 'react'
import { updateFolder } from '../actions/platformActions'
import Message from './Message'
import { Spinner } from 'react-bootstrap'

function EditFavoriteModal({meta, closeCallback}) {

  const dispatch = useDispatch()
  
  const [folderName, setFolderName] = useState(meta.name)
  const [folderDescription, setFolderDescription] = useState(meta.description)

  const changeNameHandler = (value) => {
    // console.log("name: ", folderName)
    setFolderName(value)
  }

  const changeDescriptionHandler = (value) => {
    // console.log("description: ", folderDescription)
    setFolderDescription(value)
  }

  const updateInfo = useSelector(state => state.updateFolder)
  const {loading, error, success} = updateInfo

  useEffect(() => {
    if(success){
      closeCallback(true)
    }
  }, [updateInfo])

  const updateHandler = () => {
    dispatch(updateFolder(meta.id, folderName, folderDescription))
  }
  return (
    <div className='modal-container'>
        <div style={{width: '25em'}} className='modal-window px-5 pb-5 pt-4 d-flex flex-column align-items-center'>

          <i onClick={e => closeCallback(false)} style={{cursor: 'pointer'}} className="fa-solid fa-xmark text-secondary fs-4 ms-auto"></i>
           <h1 style={{color: '#313A52'}} className='bold-title fs-1'>Edit Folder</h1>
           
            
              <div className='d-flex flex-column w-100'>
                <span className='p-label-2'>Name:</span>
                <input onChange={e => changeNameHandler(e.target.value)} value={folderName} className='p-input my-2' placeholder='Folder name' type="text" />
                <span className='p-label-2'>Description:</span>
                <textarea onChange={e => changeDescriptionHandler(e.target.value)} value={folderDescription} className='p-text-area my-2' rows='3' placeholder='Folder description' name="" id=""></textarea>
              </div>
            
            {loading ? (
              <div className='d-flex justify-content-center align-items-center'>
                <Spinner animation="border" as={'span'} style={{width: '20px', height: '20px'}}/>
              </div>
            ): error ? (
              <>
                <Message variant={'danger'}>{error}</Message>
                <div onClick={updateHandler} style={{width: '100%'}} className='login-button mt-3'>Apply Changes</div>
              </>
            ): (
              <div onClick={updateHandler} style={{width: '100%'}} className='login-button mt-3'>Apply Changes</div>
            )}
              
        </div>
      </div>
  )
}

export default EditFavoriteModal
