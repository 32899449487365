import '../styles/account.css'
import '../styles/platform.css'
import React, { useEffect, useState } from 'react'
import Header2 from '../components/Header2'
import Footer from '../components/Footer'
import SideBar from '../components/SideBar'
import DeleteModal from '../components/DeleteModal'
import ChangeAvatarModal from '../components/ChangeAvatarModal'
import MessageModal from '../components/MessageModal'
import Message from '../components/Message'
import SideResponsiveOpen from '../components/SideResponsiveOpen'
import { 
  updateName,
  refreshUser,
  updatePassword,
 } from '../actions/userActions'
import { Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  UPDATE_IMAGE_RESET,
  UPDATE_NAME_RESET,
  UPDATE_PASSWORD_RESET,
} from '../constants/userConstants'

function AccountScreen() {

  const dispatch = useDispatch()

  const [deleteModal, setDeleteModal] = useState(false)
  const [changeAvatarModal, setChangeAvatarModal] = useState(false)

  const [messageModal, setMessageModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalMessage, setModalMessage] = useState('')

  const [name, setName] = useState('')

  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')

  const userLogin = useSelector(state => state.userLogin)
  const {userInfo} = userLogin


  const openDeleteHandler = () => {
    setDeleteModal(true)
  }
  const openChangeAvatarHandler = () => {
    setChangeAvatarModal(true)
  }
  const closeDeleteHandler = () => {
    setDeleteModal(false)
  }
  const closeChangeAvatarHandler = (isSaved) => {
    setChangeAvatarModal(false)
    if(isSaved){
      setModalTitle('Updated')
      setModalMessage('The user picture has been successfully updated.')
      setMessageModal(true)
      dispatch({
        type: UPDATE_IMAGE_RESET
      })
    }
  }

  const closeMessageHandler = () => {
    setMessageModal(false)
    setModalTitle('')
    setModalMessage('')
  }

  const submitNameHandler = () => {
    dispatch(updateName(name))
  }

  const updateInfo = useSelector(state => state.updateName)
  const {loading, error, success} = updateInfo



  useEffect(() => {
    if(success){
      const udpatedName = name
      const localUser = localStorage.getItem('userInfo');
      let userObject = JSON.parse(localUser)
      userObject.name = udpatedName
      dispatch(refreshUser(userObject))
      setName('')

      setModalTitle('Updated')
      setModalMessage('The name has been successfully updated.')
      setMessageModal(true)

      dispatch({
        type: UPDATE_NAME_RESET,
      })
    }
  }, [updateInfo])

  const submitPasswordHandler = () => {
    dispatch(updatePassword(oldPassword, newPassword))
  }

  const passwordInfo = useSelector(state => state.updatePassword)
  const {loading: loadingPassword, error: errorPassword, success: successPassword} = passwordInfo

  useEffect(() => {
    if(successPassword){
      setOldPassword('')
      setNewPassword('')

      setModalTitle('Updated')
      setModalMessage('The password has been successfully updated.')
      setMessageModal(true)

      dispatch({
        type: UPDATE_PASSWORD_RESET,
      })
    }
  }, [passwordInfo])


  return (
    <div className='h-100 d-flex flex-column'>
        <Header2 />
        <div className='d-flex' style={{backgroundColor: '#F4F5F7', flex: '1'}}>
            <SideBar />
            <div className='account-main-container'>
              <h1 className='p-title pb-4'>Account Settings</h1>

              <p className='p-subtitle'>Edit profile</p>
              <p className='p-paragraph'>Edit your profile by updating your avatar image, changing your display name, and resetting your password for enhanced security.</p>


              <div className='d-flex align-items-center py-5'>
                <img src={`/static/account/${(userInfo && userInfo.profile_image) ? userInfo.profile_image: 'unknown.svg'}`} alt="" />
                <p onClick={openChangeAvatarHandler} style={{cursor: 'pointer'}} className='p-label m-0 ps-4'>Change avatar</p>
              </div>

              <div className='d-flex flex-column mb-5'>
                <span className='p-label pb-2'>Edit Name:</span>
                <div className='d-flex'>
                  <input value={name} onChange={e => setName(e.target.value)} className='p-input me-2' placeholder='Your new name' type="text" />
                    {loading ? (
                      <div className='p-button-2'>
                        <Spinner animation="border" variant='light' as={'span'} style={{width: '20px', height: '20px'}}/>
                      </div>
                    ): (
                      <div onClick={submitNameHandler} className={`p-button-2 ${(name && name != '') ? '': 'button-disabled'}`}>
                        Submit
                      </div>
                  )}

                  {error && (
                    <div className='ps-2'>
                      <Message variant={'danger'}>{error}</Message>
                    </div>
                  )}
                </div>
              </div>

              
              {userInfo && !userInfo.google_auth && (
                <div className='d-flex flex-column mb-5'>
                  <span className='p-label pb-2'>Edit Password:</span>
                  <div>
                    <input value={oldPassword} onChange={e => setOldPassword(e.target.value)} className='p-input' placeholder='Enter your old password' type="password" />
                  </div>
                  <div className='d-flex py-3'>
                    <input value={newPassword} onChange={e => setNewPassword(e.target.value)} className='p-input me-2' placeholder='Your new password' type="password" />
                    {loadingPassword ? (
                        <div className='p-button-2'>
                          <Spinner animation="border" variant='light' as={'span'} style={{width: '20px', height: '20px'}}/>
                        </div>
                      ): (
                        <div onClick={submitPasswordHandler} className={`p-button-2 ${(oldPassword && newPassword && oldPassword != '' && newPassword != '') ? '': 'button-disabled'}`}>
                          Submit
                        </div>
                    )}

                    {errorPassword && (
                      <div className='ps-2'>
                        <Message variant={'danger'}>{errorPassword}</Message>
                      </div>
                    )}
                  </div>
                </div>
              )}

              

              <p className='p-subtitle'>Delete Account</p>

              <p className='p-paragraph'>Delete your account to permanently remove all your data and deactivate your profile.</p>

              <div onClick={openDeleteHandler} className='p-button mb-5'>Delete Account</div>
              
            </div>
        </div>

        <SideResponsiveOpen />
        <Footer />

        {messageModal && (
          <MessageModal closeCallback={closeMessageHandler} title={modalTitle} message={modalMessage} />
        )}

        {deleteModal && (
          <DeleteModal closeCallback={closeDeleteHandler} />
        )}

        {changeAvatarModal && (
          <ChangeAvatarModal closeCallback={closeChangeAvatarHandler} />
        )}
    </div>
  )
}

export default AccountScreen
