import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Chart from 'react-apexcharts';    
import { getData } from '../actions/viewerActions'
import { getSeriesTracker } from '../actions/platformActions'
import { roundy } from '../components/Utilities';
import DeleteTrackerModal from '../components/DeleteTrackerModal'
import EditTrackerModal from '../components/EditTrackerModal'
import ViewerModal from '../components/ViewerModal'
import { DELETE_TRACKER_RESET } from '../constants/platformConstants'

const Widget = React.memo(function Widget({data}) {

    // console.log("DATA: ", data)

    const dispatch = useDispatch()

    const [deleteTrackerModal, setDeleteTrackerModal] = useState(false)
    const [editTrackerModal, setEditTrackerModal] = useState(false)
    const [viewerModal, setViewerModal] = useState(false)

    const [selectedTracker, setSelectedDelete] = useState(data.tracker_id)

    const [serieData, setSerieData] = useState({
        id: data.serie_id,
        name: data.series_name,
        folder_id: data.folder_id,
        folder_name: data.folder_name,
    })


    const [actions, setActions] = useState(null)
    const [xdata, setXdata] = useState([])
    const [ydata, setYdata] = useState([])
    const [xAnnotation, setXannotation] = useState([])
    const [yAnnotation, setYannotation] = useState([])
    const [highlight, setHighlight] = useState([])
        
    const chartOptions = {
        chart: {
            id: 'basic-line-chart',
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
        },
        stroke: {
            width: 3,
            curve: 'smooth'
        },
        colors: data.country_list.map(item => item.color),
        plotOptions: {
            bar: {
                columnWidth: '100%',
                barHeight: '100%',
                borderRadius: 1,


            },
            area: {
                fillTo: 'origin'
            },
            line: {
                isSloperChart: true,
            }
        },
        xaxis: {
            labels: {
                rotate: -90,
                rotateAlways: true,
                style: {
                    fontSize: '11px',
                    fontFamily: 'sans-serif'
                }
            },
            axisTicks: {
                show: false,
            },
            tickAmount: 10,
            // type: 'datetime',
            categories: xdata.map(item => item.split('-')[0] + ' - ' + item.split('-')[1]),
        },
        yaxis: {
            title: {
                text: data.unit,
                style: {
                    color: '#333333',
                    fontSize: '12px',
                    fontFamily: 'Open Sans',
                    fontWeigth: 600
                }
            },
            labels: {
                style: {
                    fontSize: '11px',
                    fontFamily: 'sans-serif'
                },
                formatter: function(value) {
                    return roundy(value)
                }
            }
        },
        fill: {
            opacity: 0.2,
            type: ydata.map(item => item.type == 'area' ? 'solid': 'gradient'),
            gradient: {
                opacityFrom: 1,
                shadeIntensity: 0,
            }
        },
        grid:
        {
            show: false,
        },
        legend: {
            show: true,
            position: 'top',
            // horizontalAlign: 'left',
            fontSize: '14px',
            fontFamily: 'Open Sans',
            fontWeigth: '300',
            // floating: true,
            labels: {
                colors: ['#333333']
            }
        },
        annotations: {
            yaxis: yAnnotation,
            xaxis: xAnnotation
        }
    };

    const series = (ydata.length > 0) ? ydata : [{name: '', data: []}]
        
        
    useEffect(()=> {

        if(data.country_list.length > 0){
            let x = []
            data.country_list.forEach(item => {
                let xtemp = []
                // console.log('temItem: ', item)
                if(item.countryType == 'country'){
                    // if(!data?.data_dict.Countries[item.value]?.data){
                    //     return
                    // }
                    xtemp = data.data.filter(element => element.country == item.country).map(element => element.date)
                    // xtemp = data?.data_dict.Countries[item.value]?.data?.filter(element => element.frequency == frequencyValue.value && element.unit == unitValue.value).map(element => element.date)
                }
                // else if(item.countryType == 'region'){
                //     if(!data?.data_dict.Regions[item.value]?.data){
                //         return
                //     }
                //     xtemp = data?.data_dict.Regions[item.value]?.data?.filter(element => element.frequency == frequencyValue.value && element.unit == unitValue.value).map(element => element.date)
                // }
                // console.log('xtemp: ', xtemp)
                // if(xtemp){
                x = [...x, ...xtemp]
                // }
            })
            x = [...new Set(x)].sort()

            setXdata(x)

            let y = data.country_list.map(item => {
                return {
                    name: item.country,
                    data: x.map(e =>
                            (data.data.filter(a => a.country == item.country).find(b => b.date == e)) ?
                            (data.data.filter(a => a.country == item.country).find(b => b.date == e)).value :
                                null
                            ),
                    type: item.type,
                    color: item.color,
                }
            })
            setYdata(y)

            let xanntemp = data.xaxis.filter(item => item.value != '').map(item => {
                return {
                    x: item.value.split('-')[0] + ' - ' + item.value.split('-')[1],
                    // x2: '2022 - 01',

                    strokeDashArray: 4,
                    borderColor: '#B6B6B6',
                    fillColor: '#B6B6B6',
                    opacity: 1
                }
            })

            let highlighttemp = data.highlight.filter(item => item.x1.value != '' && item.x2.value != '').map(item => {
                return {
                    x: item.x1.value.split('-')[0] + ' - ' + item.x1.value.split('-')[1],
                    x2: item.x2.value.split('-')[0] + ' - ' + item.x2.value.split('-')[1],
                    // x2: '2022 - 01',

                    strokeDashArray: 4,
                    borderColor: '#B6B6B6',
                    fillColor: '#B6B6B6',
                    opacity: 0.5
                }
            })

            // let d =  [...xanntemp]
            let d =  [...xanntemp, ...highlighttemp]
            setXannotation(d)

            let yanntemp = data.yaxis.filter(item => item.value != '').map(item => {
                return {
                    y: item.value,
                    // x2: '2022 - 01',
                    
                    strokeDashArray: 4,
                    borderColor: '#B6B6B6',
                    fillColor: '#B6B6B6',
                    opacity: 1,
                    label: {
                        text: item.text,
                        position: 'left',
                        offsetX: 25,
                        style: {
                            background: '#2aac9c',
                            color: '#ffffff',
                            fontFamily: 'Open Sans',
                        }
                    }
                }
            })

        //     let metrictemp = metricValue.filter(item => item.value != '').map(item => {

        //         let met = 0
        //         countryValue.filter(e => e.value != '').forEach(country => {
        //             if(country.countryType == 'country'){
        //                 let gg = data.data_dict.Countries[country.value]?.statistics?.[`${frequencyValue.value}_${unitValue.value}`]?.[item.value]
        //                 met += parseFloat(gg)
        //             }
        //             else if(country.countryType == 'region'){
        //                 let gg = data.data_dict.Regions[country.value]?.statistics?.[`${frequencyValue.value}_${unitValue.value}`]?.[item.value]
        //                 met += parseFloat(gg)
        //             }
        //         })
        //         // console.log('met', met)

        //         let metricY = null
        //         if(met){   
        //             metricY = met / countryValue.filter(e => e.value != '').length
        //         }

        //         if(metricY){

        //             return {
        //                 y: metricY,
        //                 strokeDashArray: 1,
        //                 borderColor: '#333333',
        //                 fillColor: '#333333',
        //                 opacity: 1,
        //                 label: {
        //                     text: item.label,
        //                     style: {
        //                         background: '#577bab',
        //                         color: '#ffffff',
        //                         fontFamily: 'Open Sans',
        //                         // padding: {
        //                         //     top: 2,
        //                         //     bottom: 5,
        //                         // }
        //                     }
        //                 }
        //             }
        //         }
        //     })
            let yfinal =  [...yanntemp]
        //     let yfinal =  [...yanntemp, ...metrictemp]

            setYannotation(yfinal)
        }
    }, [])

    const mouseEnterHandler = () => {        
        setActions(true)
    }

    const mouseLeaveHandler = () => {
        setActions(false)
    }

    const openDeleteHandler = () => {
        dispatch({
            type: DELETE_TRACKER_RESET,
        })
        setDeleteTrackerModal(true)
    }

    const openEditHandler = () => {
        setEditTrackerModal(true)
    }

    const closeDeleteHandler = (isDeleted) => {
        if(isDeleted){
            dispatch(getSeriesTracker())
        }
        setDeleteTrackerModal(false)
    }
    const openViewerSeries = () => {
        setViewerModal(true)
    }
    

    const closeViewerHandler = () => {
        setViewerModal(false)
    }

    const closeEditHandler = (isEdited) => {
        if(isEdited){
            dispatch(getSeriesTracker())
        }
        setEditTrackerModal(false)
    }

  return (
    <div>

        <div className='d-flex align-items-center justify-content-between'>
            <div style={{flex: '1'}}>
                <span onMouseEnter={mouseEnterHandler} onMouseLeave={mouseLeaveHandler} className='search-tracker-actions'>

                    {!actions && (
                        <i style={{color: '#606060'}} className="fa-solid fa-ellipsis-vertical"></i>
                    )}
                
                
                    {actions && (
                    <>
                        <i onMouseDown={openDeleteHandler} className="fa-regular fa-trash-can"></i>
                        <i onMouseDown={openEditHandler} className="fa-solid fa-pen-to-square"></i>
                        <i onMouseDown={openViewerSeries} className="fa-solid fa-expand"></i>
                    </>
                    )}
                    
                
                </span>
            </div>
            <div>
            <p className='search-tracker-title'>{data.series_name}</p>
            <p className='search-tracker-subtitle'>{data.frequency}</p>
            </div>
            <div style={{flex: '1'}}></div>
        </div>
        <Chart options={chartOptions} series={series} type="line" height={400} width={'100%'}/>

        {/* <button onClick={openDeleteHandler}>asdg</button> */}

        {deleteTrackerModal && (
          <DeleteTrackerModal tracker={selectedTracker} closeCallback={closeDeleteHandler} />
        )}

        {editTrackerModal && (
            <EditTrackerModal tracker={data.tracker_id} closeCallback={closeEditHandler} />
        )}

        {viewerModal && (
            <ViewerModal meta={data.meta_data} closeCallback={closeViewerHandler} isSaved={serieData} />
        )}
    </div>
  )
})

export default Widget
