import '../styles/home_platform.css'
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import React, { useEffect, useState } from 'react'
import {timeAgo} from '../components/Utilities'
import Header2 from '../components/Header2'
import Footer from '../components/Footer'
import SideBar from '../components/SideBar'
import Widget from '../components/Widget'
import HomeAddSerieModal from '../components/HomeAddSerieModal'
import DashboardHomeItem from '../components/DashboardHomeItem'
import SideResponsiveOpen from '../components/SideResponsiveOpen'
import { useDispatch, useSelector } from 'react-redux'
import { OverlayTrigger, Tooltip, Spinner, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'
import GridLayout, {Responsive, WidthProvider} from "react-grid-layout";
import {
  getFavoriteCount,
  getDashboardCount,
  getSeriesTracker,
  getRecentSearch,
  getHomeDashboards,
} from '../actions/platformActions'

import Message from '../components/Message'
import { CREATE_TRACKER_RESET } from '../constants/platformConstants'

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
  });

  useEffect(() => {
      const handleResize = () => {
          setWindowSize({
              width: window.innerWidth,
              height: window.innerHeight,
          });
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

function HomeScreen() {


  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userLogin = useSelector(state => state.userLogin)
  const {userInfo} = userLogin

  const { width } = useWindowSize();
  const [colsValue, setColsValue] = useState(1);

  const ResponsiveReactGridLayout = WidthProvider(Responsive);

  const [addSerieModal, setAddSerieModal] = useState(false)
  const [serieOrder, setSerieOrder] = useState(null)  

  const [selectedSerie, setSelectedSerie] = useState(null)
  const [currentBreakpoint, setCurrentBreakpoint] = useState("lg");
  const [compactType, setCompactType] = useState("horizontal");
  const [mounted, setMounted] = useState(false);
  const [toolbox, setToolbox] = useState({
    lg: []
  });

  useEffect(() => {
    dispatch(getFavoriteCount())
    dispatch(getDashboardCount())
    dispatch(getSeriesTracker())
    dispatch(getRecentSearch())
    dispatch(getHomeDashboards())
  }, [])

  useEffect(() => {
    if(width < 660){
      setColsValue(1)
    }else{
      setColsValue(2)
    }
  }, [width])


  const favoriteCountInfo = useSelector(state => state.favoriteCount)
  const {loading: loadingFavorite, error: errorFavorite, count: countFavorite} = favoriteCountInfo

  const dashboardCountInfo = useSelector(state => state.dashboardCount)
  const {loading: loadingDashboard, error: errorDashboard, count: countDashboard} = dashboardCountInfo

  const trackerInfo = useSelector(state => state.seriesTracker)
  const {loading: loadingTracker, error: errorTracker, series} = trackerInfo

  const dashboardHomeInfo = useSelector(state => state.dashboardsHome)
  const {loading: loadingDashboardHome, error: errorDashboardHome, dashboards} = dashboardHomeInfo

  const recentSearchInfo = useSelector(state => state.recentSearch)
  const {loading: loadingRecentSearch, error: errorRecentSearch, recentSearch: recentSearchList} = recentSearchInfo

  useEffect(() => {
    if(series){
      setSerieOrder(Math.max(...series.map(item => item.order)))
    }
  }, [trackerInfo])

  // const GoToFav = () => {window.location.href = `${window.location.origin}/#/favorite`}
  const GoToFav = () => {
    navigate('/favorite')
  }

  const GoToDash = () => {
    navigate('/dashboard')
  }

  const defaultProps = {
    className: "layout",
    rowHeight: 450,
    // onLayoutChange: (layout: any, layouts: any) => {},
    cols: { lg: colsValue, md: 1, sm: 1, xs: 1, xxs: 1 },
    breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
    containerPadding: [0, 0]
  };

  const onLayoutChange = (layout, layouts) => {
    console.log("OLC: ", layout, layouts)
    // setLayouts({...layouts});
  };

const onDrop = (layout, layoutItem, _ev) => {
    // console.log("OD: ", layout)
    alert(
      `Element parameters:\n${JSON.stringify(
        layoutItem,
        ["x", "y", "w", "h"],
        2
      )}`
    );
  };

// const onResize = (layouts) => {
//     console.log("onresize: ", layouts)
//     setLayout(layouts)
// };

const onBreakpointChange = (breakpoint) => {
    console.log("OBC: ", breakpoint)
    setCurrentBreakpoint(breakpoint);
    setToolbox({
      ...toolbox,
      [breakpoint]: toolbox[breakpoint] || toolbox[currentBreakpoint] || []
    });
  };


const mouseEnterHandler = (name) => {
  console.log(name)
  // let a = name
  setSelectedSerie(name)
}

const mouseLeaveHandler = () => {
  selectedSerie.current = null
}

const openAddSerieModal = () => {
  dispatch({
    type: CREATE_TRACKER_RESET,
  })
  setAddSerieModal(true)
}

const closeAddModalHandler = (isChange) => {
  if(isChange){
    dispatch(getSeriesTracker())
  }
  setAddSerieModal(false)
}

const searchRecentHandler = (item) => {
  navigate(`/search?area=${encodeURIComponent(item.area_id)}&topic=${encodeURIComponent(item.topic_id)}&indicator=${encodeURIComponent(item.indicator_id)}`);
}

  return (
    <div className='h-100 d-flex flex-column'>
        <Header2 />
        <div className='d-flex' style={{backgroundColor: '#F4F5F7', flex: '1'}}>
            <SideBar />
            <div className='home-main-container'>
              <Container fluid>

                <h1 className='p-title '>Home</h1>
                <p className='p-paragraph'>Welcome back, {userInfo && userInfo.name}</p>
                <hr />

                <div className='d-flex justify-content-between flex-wrap pb-5'>

                  <div className='home-card d-flex py-4 px-4'>
                    <div className='d-flex align-items-center px-4'>
                      <i className="fa-solid fa-grip-vertical"></i>
                    </div>
                    <div>
                      <div className='d-flex align-items-center justify-content-between'>
                          {loadingDashboard ? (
                              <Spinner animation="border" as={'span'} style={{width: '20px', height: '20px'}}/>
                          ): errorDashboard ? (
                            <span style={{fontSize: '50px'}} className='fw-bold ps-3'>
                              -
                            </span>
                          ): (
                            <span style={{fontSize: '50px'}} className='fw-bold ps-3'>
                              {countDashboard}
                            </span>
                          )}
                        <p style={{color: '#8A8A8A'}} className='p-paragraph m-0'> dashboards</p>
                      </div>
                      
                      <div onClick={GoToDash} className='button-dark-empty'>Go to Dashboard</div>
                      
                    </div>
                  </div>

                  <div className='home-card d-flex py-4 px-4'>
                    <div className='d-flex align-items-center px-4'>
                      <i className="fa-solid fa-arrow-trend-up"></i>
                    </div>
                    <div>
                      <div className='d-flex align-items-center justify-content-between'>
                          {loadingFavorite ? (
                              <Spinner animation="border" as={'span'} style={{width: '20px', height: '20px'}}/>
                          ): errorFavorite ? (
                            <span style={{fontSize: '50px'}} className='fw-bold ps-3'>
                              -
                            </span>
                          ): (
                            <span style={{fontSize: '50px'}} className='fw-bold ps-3'>
                              {countFavorite}
                            </span>
                          )}
                        <p style={{color: '#8A8A8A'}} className='p-paragraph m-0 ps-2'> favorites</p>
                      </div>
                      <div onClick={GoToFav} className='button-dark-empty'>Go to Favorite</div>
                    </div>
                  </div>

                  <div className='home-card d-flex flex-column'>
                    <div className='home-search-header'>
                      <i className="fa-solid fa-magnifying-glass pe-2"></i>
                      Your Recent Search
                    </div>
                    <div style={{flex: '1'}} className='d-flex flex-column justify-content-center overflow-hidden'>
                      <div style={{overflowY: 'auto'}}>
                        {loadingRecentSearch && (
                          <div className='w-100 d-flex justify-content-center'>
                            <Spinner animation="border" as={'span'} style={{width: '20px', height: '20px'}}/>
                          </div>
                        )}

                        {errorRecentSearch && (
                          <Message variant={'danger'}>{errorRecentSearch}</Message>
                        )}

                        {recentSearchList && recentSearchList.map((item, index) => (
                          <div key={index} className='home-search-container'>
                            <p onClick={e => searchRecentHandler(item)} className='home-search-card-item'>{item.indicator}</p>
                            <p className='home-search-card-detail'>{timeAgo(item.created_at)}</p>
                          </div>
                        ))}
                      </div>
                    </div>


                  </div>

                </div>

                <div className='d-flex align-items-center'>
                  <i className="fa-solid fa-chart-bar"></i>
                  <p className='p-subtitle m-0 ps-2'>Series Tracker</p>
                </div>
                <hr />
                <p style={{color: '#616161'}} className='p-paragraph'>
                  Keep track of your favories and don’t miss anything. You can only add series that you have on your favorites.
                </p>

                <div style={{width: '10em'}} onClick={openAddSerieModal} className='button-dark-empty'>Add Series</div>
                

                <div className='py-4'>
                  {loadingTracker ? (
                    <div className='d-flex justify-content-center'>
                      <Spinner animation="border" as={'span'} style={{width: '20px', height: '20px'}}/>
                    </div>
                  ): errorTracker ? (
                    <Message variant={'danger'}>{errorTracker}</Message>
                  ): (
                    <ResponsiveReactGridLayout
                      {...defaultProps}
                      // cols={{lg: 2}}
                      // breakpoints={{lg: 1200}}
                      // style={{ background: "red" }}
                      // layouts={layouts}
                      // onDragStart={(layout, oldItem, newItem, placeholder) => {
                      //   placeholder.style.backgroundColor = 'green'
                      // }}
                      measureBeforeMount={false}
                      isResizable={false}
                      useCSSTransforms={mounted}
                      compactType={compactType}
                      preventCollision={!compactType}
                      // onLayoutChange={onLayoutChange}
                      // onBreakpointChange={onBreakpointChange}
                      onDrop={onDrop}
                      isDroppable
                      >
                          {/* {generateDOM()} */}
                          {series && series.map((item, index) => (
                            <div key={index.toString()} data-grid={{x: (index % 2), y: Math.floor(index/2), w: 1, h: 1}}>
                              <Widget data={item} />
                            </div>
                          ))}
                    </ResponsiveReactGridLayout>
                  )}

                </div>


                <div className='d-flex align-items-center pt-4'>
                  <i className="fa-solid fa-grip"></i>
                  <p className='p-subtitle m-0 ps-2'>Dashboards</p>
                </div>
                <hr />
                <p style={{color: '#616161'}} className='p-paragraph'>
                  Quick access to your most used dashboards.
                </p>
                {dashboards && dashboards.map((item, index) => (
                  <DashboardHomeItem key={index} data={item} />
                ))}
                
              </Container>
            </div>
        </div>

        <SideResponsiveOpen />
        <Footer />

        {addSerieModal && (
          <HomeAddSerieModal order={serieOrder} closeCallback={closeAddModalHandler} />
        )}
    </div>
  )
}

export default HomeScreen
