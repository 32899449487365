import axios from 'axios'
import {
    GET_DATA_REQUEST,
    GET_DATA_SUCCESS,
    GET_DATA_FAIL,

    SAVE_SERIES_REQUEST,
    SAVE_SERIES_SUCCESS,
    SAVE_SERIES_FAIL,

    UPDATE_SERIES_REQUEST,
    UPDATE_SERIES_SUCCESS,
    UPDATE_SERIES_FAIL,
    UPDATE_SERIES_RESET,
} from '../constants/viewerConstants'

export const getData = (area, topic, indicator) => async (dispatch) => {
    try{
        dispatch({
            type: GET_DATA_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }

        const {data} = await axios.post(
            '/api/v1/data/viewer-data/',{
                area: area,
                topic: topic,
                indicator: indicator,
                country: [],
                unit: "",
                frequency: "",
            },
            config
        )

        dispatch({
            type: GET_DATA_SUCCESS,
            payload: data,
        })


    }catch(error){
        dispatch({
            type: GET_DATA_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const saveSeries = (folders, meta, name) => async (dispatch, getState) => {
    try{
        dispatch({
            type: SAVE_SERIES_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const {data} = await axios.post(
            '/api/v1/save-series/',{
                folder_ids: folders,
                meta_data: meta,
                series_name: name,
            },
            config
        )

        dispatch({
            type: SAVE_SERIES_SUCCESS,
        })


    }catch(error){
        dispatch({
            type: SAVE_SERIES_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateSeries = (id, meta, name) => async (dispatch, getState) => {
    try{
        dispatch({
            type: UPDATE_SERIES_REQUEST
        })

        const {
            userLogin: { userInfo }
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access}`
            }
        }

        const {data} = await axios.put(
            `/api/v1/update-series/${id}/`,{
                meta_data: meta,
                series_name: name,
            },
            config
        )

        dispatch({
            type: UPDATE_SERIES_SUCCESS,
        })


    }catch(error){
        dispatch({
            type: UPDATE_SERIES_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}