import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import Header2 from '../components/Header2'
import Footer from '../components/Footer'
import SideBar from '../components/SideBar'
import SideResponsiveOpen from '../components/SideResponsiveOpen'
import NoDashboardCard from "../components/NoDashboardsCard"
import DashboardListItem from "../components/DashboardListItem"
import DeleteDashboardModal from '../components/DeleteDashboardModal'
import Message from "../components/Message"
import {
  getDashboardList
} from '../actions/platformActions'
import {
  CREATE_DASHBOARD_RESET,
  DELETE_DASHBOARD_RESET,
} from '../constants/platformConstants'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'


function DashboardScreen() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [deleteDashboardModal, setDeleteDashboardModal] = useState(false)
  const [selectedDashboard, setSelectedDashboard] = useState(null)
  const [dashboardList, setDashboardList] = useState([])

  useEffect(() => {
    dispatch({
      type: CREATE_DASHBOARD_RESET
    })
    dispatch(getDashboardList())
  }, [])

  const dashboardInfo = useSelector(state => state.dashboardList)
  let {loading, error, dashboards} = dashboardInfo

  const isEmpty = (dashboardList.length == 0)

  useEffect(() => {
    if(dashboards){
      setDashboardList(dashboards.data)
    }
  }, [dashboardInfo])
  
  const closeDeleteDashboardHandler = (isDeleted, did) => {
    setDeleteDashboardModal(false)
    // dashboards = dashboards.filter(item => item.id != did)
    if(isDeleted){
      setDashboardList(dashboardList.filter(item => item.id != did))
    }
  }

  const deleteDashboardHandler = (did) => {
    dispatch({
      type: DELETE_DASHBOARD_RESET,
    })
    setSelectedDashboard(did)
    setDeleteDashboardModal(true)
  }

  return (
    <div className='h-100 d-flex flex-column'>
        <Header2 />
        <div className='d-flex' style={{backgroundColor: '#F4F5F7', flex: '1'}}>
            <SideBar />
            <div className='dashboard-main-container'>
              <h1 className='p-title pb-4'>Dashboards</h1>
              <NoDashboardCard isEmpty={isEmpty} />
                
              <hr />
              <p style={{color: '#616161'}} className='p-paragraph'>
                Below is the list of created dashboards. Select a dashboard to access its detailed visualizations and data analysis.
              </p>
              {loading && (
                <div className='d-flex justify-content-center'>
                  <Spinner animation="border" as={'span'} style={{width: '20px', height: '20px'}}/>
                </div>
              )}
              { error && (
                <Message variant='danger'>{error}</Message>
              )}
              {dashboards && dashboardList.map(item => (
                <div key={item.id} className='d-flex align-items-center'>
                  <DashboardListItem data={item} />
                  <i onClick={e => deleteDashboardHandler(item.id)} className="fa-solid fa-trash ps-3" style={{cursor: 'pointer', color: '#A5A5A5', fontSize: '16px'}}></i>
                </div>
              ))}
              
            </div>
            {deleteDashboardModal && (
              <DeleteDashboardModal closeCallback={closeDeleteDashboardHandler} did={selectedDashboard} />
            )}
        </div>
        
        <SideResponsiveOpen />
        <Footer />
    </div>
  )
}

export default DashboardScreen
